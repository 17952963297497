import * as React from 'react';
import {Auth, Provider} from './Firebase';
import Utils from './Utils.js';
import API from './API.js';

class Profile extends React.Component {
	constructor(props) {
		super();

		this.state = {
			currentItem: '',
			username: '',
			items: [],
			location: null,
			userData: null,
			userPhoto: null
		}

		this.goToAvatarCreation = this.goToAvatarCreation.bind(this); // <-- add this line
		this.login = this.login.bind(this); // <-- add this line
		this.logout = this.logout.bind(this); // <-- add this line
	}

	componentDidMount() {
		Auth.onAuthStateChanged((user) => {
			// console.log('auth change', user)
			if (user) {
				// console.log(user.photoURL, user.phoneNumber, user)
				// get 3d thumbnail
				if (user.phoneNumber) {
					API.getUser(user.phoneNumber, (userData) => {
						if (userData && userData.thumbnail ) {
							// console.log("got user", userData);
							// userPhoto
							this.setState({userPhoto: userData.thumbnail.imageURL})
						} else {
							// try generating one or setting default
						}
					})

					if (this.props.history.location.pathname === "/") {
						setTimeout(() => { this.props.history.push('/lobby') }, 500);
					}
				}

				this._updateUserData(user);
				// this.identifyUser(user)
			} else {
				console.log('no state change', this.props.required)
				// todo: allow authless
				if (this.props.required) {
			        this.props.history.push(`/login`);
		        }

			}
		});
	}

	componentDidUpdate(prevProps, prevState) {
	}

	render() {
		return (
			<div
				className={this.props.show ? "z2 scaleIn" : "scaleOut "}
			>

				{this.state.userData ?
					<div className="flex items-center">
						<button
							className={`circle shadowLarge p0 hover isolation`}
							onClick={this.logout}
							style={{
								width: 48,
								height: 48
							}}
						>
							<img
								className="circle full"
								alt={this.state.userData.displayName}
								src={this.state.userPhoto ? Utils.resizeUserPhoto(this.state.userPhoto) : null}
							/>
						</button>
					</div>
					:
					<div style={{isolation: "auto"}}>
						<a className="exclusion" href="login">login</a>
					</div>
				}
			</div>

		);
	}

	_updateUserData = (user) => {
		// console.log("getting user data", user.phoneNumber)
		const userData = API.setCurrentUserData(user)
		this.setState({ userData });
	}

	goToPage = (url) => {
		this.props.history.push(`/${url}`)
	}

	goToLogin = () => {
		console.log('somehow sending someone to login')
		this.props.history.push('/login')
	}

	goToAvatarCreation = () => {
		console.log('somehow sending someone here')
		this.props.history.push('/avatar')
	}

	logout() {
		Auth.signOut().then(() => {
			const userData = null;

			localStorage.removeItem('currentUser');
			this.setState({ userData });
			// this.goToLogin()
			this.props.history.push('/login')
			// window.location.reload(false);
		});
	}

	login() {
		Auth.signInWithRedirect(Provider).then((result) => {
			const userData = result.user;
			this.setState({userData});
		});
	}
}

export default Profile;