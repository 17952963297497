// https://threejsfundamentals.org/threejs/lessons/threejs-align-html-elements-to-3d.html

import * as React from 'react';

import Utils from './Utils.js';
import Moment from 'moment';

import '../css/AvatarLabel.css';

class AvatarLabel extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
		}
	}

	componentDidMount() {
		// console.log("label mount", this.props.videoStream)
		this._playStream(this.props.videoStream);
	}

	render() {
		return (
			<div>
				{this.props.videoStream ?
					<div
						id={`video_${this.props.id}`}
						ref={video => {this.video = video}}
						className="avatarVideoContainer mx-auto pointer"
					/>
					: null
				}

				<div
					ref={(container) => { this.container = container }}
					className="avatarLabel"
					onClick={this._labelClickHandler.bind(this)}
				>

					<i
						className={`audioIndicator exclusion material-icons hover ${this.props.audioState === "talking" ? "scaleIn eqScale" : this.props.audioState === "muted" ? "scaleIn" : ""}`}
					>
						{`${this.props.audioState === "talking" ? "graphic_eq" : this.props.audioState === "muted" ? "mic_off" : ""}`}
					</i>

					{this.props.name}
				</div>
			</div>
		);
	}

	_playStream(stream) {
		// console.log("play", stream)
		// if (this.props.videoStream) {
		// }
		// console.log("stream", this.props.videoStream, this.props.videoStream ? [this.props.videoStream.isPlaying, this.props.videoStream._enabled]: null )

		if (stream) {
			stream.play(this.video);
		}
	}

	_labelClickHandler() {
		console.log(`i'm clicking ${this.props.name} w/ their id ${this.props.id}`)

		// window.open(`https://foursquare.com/v/${this.props.id}`, '_blank');
		// this.props.history.push(`/lobby/${this.props.id}`);
		window.location.href=`/lobby/${this.props.id}`;
	}
}
export default AvatarLabel;