import * as THREE from 'three';
import API from "./API.js";
import TWEEN from '@tweenjs/tween.js'
import Utils from './Utils.js';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

import bronte from '../res/bronte.glb'
import privateRoom from '../res/private_room.glb'
import gallery from '../res/gallery.glb'
import boardRoom from '../res/boardroom.glb'
import lobby from '../res/lobby.glb'


let bgColor = 0xe5e5e5;
let gridColor = 0x000000;
let randoColor = null;
let sceneBG = null;

let sceneBGs = {
	"bronte": bronte,
	"lobby": lobby,
	"privateRoom": privateRoom,
	"boardRoom": boardRoom,
	"gallery": gallery,
}

let sceneBGCycle = Utils.cycle(Utils.shuffle(Object.keys(sceneBGs)))

if (Utils.isNight()) {
	bgColor = 0x222222;
	gridColor = 0xffffff;
}

let grid = new THREE.GridHelper( 6000, 100, gridColor, gridColor );


class SceneBackground {
	constructor(scene, bgObject, bgColor, statusMethod) {
		this.scene = scene || null;
		this.bgColor = bgColor || null;
		this.bgObject = bgObject;
		this.gltf = null;
		this.mixer = null;
		this.statusMethod = statusMethod || null;

		// console.log("bgcolor", this.bgColor)
		this.init(this.bgColor)
	}

	init(bgColor) {
		// console.log("png", this.scene)

		// setInterval(() => {
		// 	API.getResource("colors", (data) => {
		// 		// console.log("datashirt", data)
		// 		// return
		// 		const randoColor = Utils.randomChoice(data.backgroundColors);
		// 		const randoGridColor = Utils.randomChoice(data.backgroundColors);

		// 		const bgColor = new THREE.Color(parseInt(randoColor));
		// 		const gridColor = new THREE.Color(parseInt(randoGridColor));

		// 		this.scene.background = new THREE.Color( bgColor );
		// 		// this.scene.fog = new THREE.Fog( bgColor, 400, 2000 );

		// 		grid = new THREE.GridHelper( 6000, 100, gridColor, gridColor );

		// 		this.scene.add( grid );
		// 	})
		// }, 1000);

		if (bgColor === "random") {
			bgColor = bgColor;

			// console.log("do it")
			API.getResource("colors", (data) => {
				randoColor = Utils.randomChoice(data.backgroundColors);
				bgColor = new THREE.Color(parseInt(randoColor));

				// console.log("bgcolor", randoColor)

				this.scene.background = new THREE.Color( bgColor );
				// this.scene.fog = new THREE.Fog( bgColor, 400, 2000 );
			})
		}

		// setup grid environment
		this.scene.background = new THREE.Color( bgColor );
		// this.scene.fog = new THREE.Fog( bgColor, 400, 2000 );

		// var grid = new THREE.GridHelper( 6000, 100, gridColor, gridColor );
		grid.material.opacity = .1;
		grid.material.transparent = true;
		grid.name = "grid";
		this.scene.add( grid );


		// GROUND
		var shadowMaterial = new THREE.ShadowMaterial();
		shadowMaterial.opacity = 0.05;

		// var ground = new THREE.Mesh( groundGeo, groundMat );
		var ground = new THREE.Mesh( new THREE.PlaneBufferGeometry( 50000, 50000 ), shadowMaterial );

		ground.position.y = 0;
		ground.rotation.x = - Math.PI / 2;
		ground.receiveShadow = true;
		this.scene.add( ground );

		// lights
		let light = new THREE.AmbientLight( 0x808080, 1 ); // soft white light scene.add( light );
		light.position.set( 0, 200, 0 );
		this.scene.add( light );

		let hemiLight = new THREE.HemisphereLight( 0xFFEECC, 0x808080, .2 );
		// hemiLight = new THREE.HemisphereLight( 0x77DDFF, 0xFFCC99, .2 );
		hemiLight.position.set( 0, 240, 0 );
		this.scene.add( hemiLight );

		// let hemiLightHelper = new THREE.HemisphereLightHelper( hemiLight, 20 );
		// this.scene.add( hemiLightHelper );

		// direction light
		let dirLight = new THREE.DirectionalLight( 0x808080, 1 );
		dirLight.position.set( -1, 2, 1 );
		dirLight.position.multiplyScalar( 190 );

		dirLight.castShadow = true;
		dirLight.shadow.mapSize.width = 512;
		dirLight.shadow.mapSize.height = 512;

		dirLight.shadow.radius = 2;

		const d = 300;

		dirLight.shadow.camera.left = -d;
		dirLight.shadow.camera.right = d;
		dirLight.shadow.camera.top = d;
		dirLight.shadow.camera.bottom = -d;

		dirLight.shadow.camera.far = 1000;
		dirLight.shadow.bias = 0.0005;
		this.scene.add( dirLight );

		// let dirLightHeper = new THREE.DirectionalLightHelper( dirLight, 20 );
		// this.scene.add( dirLightHeper );

		// helpers
		// //ADD CUBE
		// const boxGeometry = new THREE.BoxGeometry(20, 20, 20);
		// const boxMaterial = new THREE.MeshBasicMaterial({ color: '#433F81' });
		// let cube = new THREE.Mesh(boxGeometry, boxMaterial);
		// // cube.position.set( 0, 0, 0 );
		// cube.position.set( 0, 160, 0 );
		// this.scene.add( cube );

		if (this.bgObject) {
			this._loadGLTF(this.bgObject, (mesh) => {
				this.scene.add(mesh);
			})
		} else {
			if (typeof this.bgObject === 'boolean' && this.bgObject === false) {
				// console.log("this is false")
				// console.log("this is else", this.bgObject)
			} else {
				if (API.getLocalStorage('selectedSceneBG')) {
					console.log("select", API.getLocalStorage('selectedSceneBG'))
					this.updateBG(sceneBGs[API.getLocalStorage('selectedSceneBG')]);
				}
			}
		}
	}

	updateBG = (environment) => {
		// console.log("new env", environment)
		// load new background
		const key = sceneBGCycle()
		let randomSceneBG = sceneBGs[key];


		if (environment) {
			randomSceneBG = environment
		} else {
			API.setLocalStorage('selectedSceneBG', key)
		}

		this._loadGLTF(randomSceneBG, (data) => {
			if (sceneBG) {
				this.scene.remove(sceneBG);
			}

			data.scale.set(0, 0, 0)
			data.rotation.set(0, Math.PI, 0)

			this.scene.add( data );
			sceneBG = data;

			const newBoxScale = new THREE.Vector3( 1, 1, 1)
			const newBoxRotation = new THREE.Vector3( 0, 0, 0)

			const duration = Utils.randomNumber(1000, 2500)

			new TWEEN.Tween( data.rotation)
				.to( newBoxRotation, duration + 500)
				.easing(TWEEN.Easing.Exponential.Out)
				.start();

			new TWEEN.Tween( data.scale)
				.to( newBoxScale, duration)
				.easing(TWEEN.Easing.Exponential.Out)
				.start();
		});;

		API.getResource("colors", (data) => {
			randoColor = Utils.randomChoice(data.backgroundColors);
			bgColor = new THREE.Color(parseInt(randoColor));

			this.scene.background = new THREE.Color( bgColor );
			// this.scene.fog = new THREE.Fog( bgColor, 400, 2000 );

			this.scene.add( grid );
		})
	}


	_loadGLTF = (url, cb) => {
		// load preset body
		const loader = new GLTFLoader();

		// Load a glTF resource
		loader.load(url, (gltf) => {
			gltf.scene.traverse((child) => {

				if ( child.isMesh ) {
					// child.castShadow = true;
					// child.receiveShadow = true;
					child.material.metalness = 0;

					// if (Utils.isNight()) {
					// 	child.material.metalness = 1;
					// } else {
					// 	child.material.metalness = .4;
					// }
				}
			})

			this.gltf = gltf;

			this.playAnimations(this.gltf);

			gltf.scene.name = "object_scene";

			cb(gltf.scene);
		},
			// called while loading is progressing
			(xhr) => {
				// console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
				if (xhr.loaded === xhr.total) {
					console.log('done loading gltf')
				}
			},
			// called when loading has errors
			(error) => {
				console.log( 'An error happened', error );
			}
		);
	}

	playAnimations() {
		if (this.gltf) {
			this.mixer = this.mixer || new THREE.AnimationMixer(this.gltf.scene);

			this.gltf.animations.forEach((clip) => {
				this.mixer.clipAction(clip).play();
			});
		}
	}

	updateMixer(delta) {
		if ( this.mixer ) this.mixer.update( delta );
	}

	_pulseObject = (object) => {
		object.traverse( child => {
			if (child.materials) {
				console.log(child, child.materials)
				// child.materials[0].transparent = true;
				// child.materials[0].opacity = 1 + Math.sin(new Date().getTime() * .0025);//or any other value you like
				// child.visible = false;
			}
			return
		});
	}

	_hideObject = (object) => {
		object.traverse( child => {
			// if (child instanceof THREE.Mesh) {
			if (child.isMesh) {
				child.visible = false;
			}
			return
		});
	}
}

export default SceneBackground;