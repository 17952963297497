import * as React from 'react';
import Utils from './Utils.js';
import API from './API.js';

// mediapipe https://stackoverflow.com/questions/67674453/how-to-run-mediapipe-facemesh-on-a-es6-node-js-environment-alike-react
// import {Camera} from '@mediapipe/camera_utils'
// import {MediaPipeControl} from '@mediapipe/control_utils'
import DrawingUtils from '@mediapipe/drawing_utils'

import {
	FaceMesh,
	FACEMESH_TESSELATION,
	FACEMESH_RIGHT_EYE,
	FACEMESH_LEFT_EYE,
	FACEMESH_RIGHT_EYEBROW,
	FACEMESH_LEFT_EYEBROW,
	FACEMESH_FACE_OVAL,
	FACEMESH_LIPS
} from '@mediapipe/face_mesh'

class FacePipe extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showLandmarks: false
		}

		this.faceMesh = null
		this.camera = null
		this.videoContainerElement = null
		this.videoLabelElement = null
		this.animationLoop = null
	}

	componentDidMount() {
	    // mediapipe
		this.faceMesh = new FaceMesh({locateFile: (file) => {
		  return `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`;
		}});

		// console.log("face", this.faceMesh)

		this.faceMesh.setOptions({
		  maxNumFaces: 1,
		  minDetectionConfidence: 0.5,
		  minTrackingConfidence: 0.5
		});

		// console.log("facemesh", this.faceMesh)
		this.faceMesh.onResults(this._calcMeshResults);
		this._renderPrediction()

		// this.camera = new Camera(this.refs.videoElement, {
		//   onFrame: async () => {
		//   	if (this.faceMesh) {
		// 	    await this.faceMesh.send({image: this.refs.videoElement});
		//   	}
		//   },
		//   width: 128,
		//   height: 128
		// });

		// this.camera.start();
	}

	componentDidUpdate(prevProps, prevState) {
	}

	componentWillUnmount() {
		console.log('unmount')
		if (this.animationLoop) {
		    cancelAnimationFrame(this.animationLoop);
		    this.animationLoop = null
		}

		this.camera = null
		this.faceMesh.close()
		this.faceMesh = null
	}


	render() {
		return (
			<div className="container absolute top-0 centerX pt3 mt1">
				{
					/*<video
						className="input_video display-none"
						ref="videoElement"
					>
					</video>*/
				}
				{ this.state.showLandmarks ?
					<canvas
						className="output_canvas"
						ref="canvasElement"
						width="320px"
						height="320px">
					</canvas>
					: null
				}
			</div>
		);
	}

	_renderPrediction = async () => {
		// console.log("render prediction", this.videoLabelElement)
		// if mediaPipe and the RTC videoElement is available, send rtc image to mediaPipe
		if (this.videoLabelElement && this.faceMesh) {
			// console.log(this.videoLabelElement)
			await this.faceMesh.send({image: this.videoLabelElement});
		} else {
			if (this.videoContainerElement) {
				this.videoLabelElement = this.videoContainerElement.getElementsByTagName('video')[0]
			} else {
				this.videoContainerElement = document.getElementById(`video_${API.getCurrentPhoneNumber()}`);
			}
		}

	    // Call itself again
	    this.animationLoop = requestAnimationFrame(this._renderPrediction);
	};

	_calcMeshResults = (results) => {
		// console.log("result", results)
		// if (this.props.scene.state.renderedAvatars && this.props.scene.state.renderedAvatars[API.getCurrentPhoneNumber()]) {
		// 	const myAvatar = this.props.scene.state.renderedAvatars[API.getCurrentPhoneNumber()];
		// 	const avatarVideo = myAvatar.label.querySelector('video')
		// 	console.log("myavatar", avatarVideo)
		// }

		const videoElement = this.refs.videoElement;

		if (this.state.showLandmarks) {
			const canvasElement = this.refs.canvasElement;
			const canvasCtx = canvasElement.getContext('2d');

			canvasCtx.save();
			canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
			// canvasCtx.drawImage(results.image, 0, 0, canvasElement.width, canvasElement.height);

			if (results.multiFaceLandmarks) {
				for (const landmarks of results.multiFaceLandmarks) {
					// console.log("png", landmarks, FACEMESH_RIGHT_EYE)
					DrawingUtils.drawConnectors(canvasCtx, landmarks, FACEMESH_TESSELATION, {color: '#cccccc', lineWidth: 1});
					DrawingUtils.drawConnectors(canvasCtx, landmarks, FACEMESH_RIGHT_EYE, {color: '#FF0000'});
					DrawingUtils.drawConnectors(canvasCtx, landmarks, FACEMESH_RIGHT_EYEBROW, {color: '#FF4444'});
					DrawingUtils.drawConnectors(canvasCtx, landmarks, FACEMESH_LEFT_EYE, {color: '#00FF00'});
					DrawingUtils.drawConnectors(canvasCtx, landmarks, FACEMESH_LEFT_EYEBROW, {color: '#44FF44'});
					DrawingUtils.drawConnectors(canvasCtx, landmarks, FACEMESH_FACE_OVAL, {color: '#0000ff'});
					DrawingUtils.drawConnectors(canvasCtx, landmarks, FACEMESH_LIPS, {color: '#0000ff'});
				}
			}
			canvasCtx.restore();
		}


		if (this.props.scene && results.multiFaceLandmarks) {
			// head
			let head = {
				position: {},
				rotation: {},
				blendshapes: {},
				expressions: {}
			};

			const mapping = [1, -1];

			const landmarks = results.multiFaceLandmarks[0]

			// global rotation

			// xRotation based on nose distance to mouth and eyes
			head.rotation.x = Utils.modulate(landmarks[1].y, [Utils.getMaxY(landmarks), Utils.getMinY(landmarks)], mapping);
			// yRotation based off of euclid distance of nose to edge, probably shoudl do jaw
			head.rotation.y = Utils.modulate(landmarks[1].x, [Utils.getMaxX(landmarks), Utils.getMinX(landmarks)], mapping);
			// zRotation based off of tangent of both eyes
			head.rotation.z = Math.atan((landmarks[263].y - landmarks[33].y)/(landmarks[263].x - landmarks[33].x));



			// face points to blendshapes https://developer.apple.com/documentation/arkit/arfaceanchor/blendshapelocation
			// head.blendshapes.eyeBlinkRight = Utils.modulate(landmarks[0].y, [landmarks[0].y, landmarks[1].y], [0, 1]);

			// roll mouth left and right
			// head.blendshapes.mouthLeft = Utils.modulate(Utils.getMaxX(landmarks), [landmarks[0].x, Utils.getMaxX(landmarks)], [0, 1]);
			// console.log("mouth", head.blendshapes.mouthLeft)
			// head.blendshapes.mouthRight = Utils.modulate(Utils.getMinX(landmarks), [landmarks[4].x, Utils.getMinX(landmarks)], mapping);

			// sides of mouth get smaller and stay closed at center
			// head.blendshapes.mouthPucker = Utils.modulate(
			// 	Utils.pointDistance( landmarks[0], landmarks[6]),
			// 	// [ Utils.pointDistance( Utils.getMidPoint(landmarks), Utils.getMidPoint(landmarks) ), Utils.pointDistance( landmarks[4], landmarks[8]) ],
			// 	[ Utils.pointDistance( landmarks[3], landmarks[0]) * 1.25, Utils.pointDistance( landmarks[3], landmarks[0]) * 1.15 ],
			// 	[0, 1]
			// );

			// console.log(head.blendshapes.mouthPucker, Utils.pointDistance( landmarks[0], landmarks[6]), Utils.pointDistance( landmarks[3], landmarks[0]) * 1.25, Utils.pointDistance( landmarks[3], landmarks[0]) )

			// console.log(landmarks, landmarks)
			// sides of mouth get closer and sides of mouth get more open
			// head.blendshapes.mouthFunnel = Utils.modulate(
			// 	landmarks[17].y - landmarks[14].y,
			// 	[Utils.getMaxY(landmarks) - Utils.getMinY(landmarks), (Utils.getMaxY(landmarks) - Utils.getMaxY(landmarks)) / 2],
			// 	[0, 1]
			// );


			// base this on drawing ratios where mouth is like 1/5th of headsize
			head.blendshapes.jawOpen = Utils.modulate(
				landmarks[14].y - landmarks[13].y,
				[0, (Utils.getMaxY(landmarks) - Utils.getMinY(landmarks)) / 5],
				[0, 1]
			);

			// console.log("mouth", head.blendshapes.jawOpen)

			// head.blendshapes.leftEyeBrow = Utils.modulate(landmarks[4].y, [landmarks[0].y, detection.detection.box.top], [0, 1], true)*2;
			// head.blendshapes.rightEyeBrow = Utils.modulate(landmarks[0].y, [landmarks[4].y, detection.detection.box.top], [0, 1], true)*2;

			// head.blendshapes.rightEyeBrow = Utils.modulate(Utils.getMinY(landmarks), [landmarks[0].y, detection.detection.box.top], [0, 1]);
			// console.log(landmarks, landmarks, head.blendshapes.leftEyeBrow, detection.detection.box.top)
			// console.log(head.blendshapes.leftEyeBrow, landmarks[0].y, landmarks[4].y, detection.detection.box.top)

			// console.log(Utils.getMaxY(landmarks) - Utils.getMinY(landmarks))
			// console.log(head.blendshapes.rightEyeBrow, Utils.getMinY(landmarks), detection.detection.box.top)

			// console.log("detection", head.blendshapes.jawOpen)
			// console.log("detection", detection.expressions);
			// console.log("landmarks", zRotation)







			// console.log("land", this.props.scene)
			this.props.scene.setAvatarHeadPosition(head);
		}
	}

}

export default FacePipe;