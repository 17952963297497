const PERSONAL_ACCESS_TOKEN = '1334-b734af44-9097-408b-9c25-e7c62ef63323';

class Figma {
	static getFileKey(pageUrl) {
		const parser = document.createElement('a');
		parser.href = pageUrl;
		return parser.pathname.replace('/file/', '').replace(/\/.*/,'');
	}

	static getNodeId(pageUrl) {
		const parser = document.createElement('a');
		parser.href = pageUrl;
		return decodeURIComponent(parser.search).replace('?node-id=','');
	}

	static apiRequest(endpoint) {
		return fetch('https://api.figma.com/v1' + endpoint, {
			method: 'GET',
			headers: { "x-figma-token": PERSONAL_ACCESS_TOKEN }
		}).then(function(response) {
			return response.json();
		}).catch(function (error) {
			return { err: error };
		});
	}

	static addImageToCanvas(imageUrl, imgElement) {
		// const img = new Image();

		// img.src = imageUrl;

		// img.onload = function () {
		//     // document.body.appendChild(img);
			// imgElement.attr("src", imageUrl);
		// };

		imgElement.attr("src", imageUrl);
	}

	static callFigmaAndDrawMockups(url, imgElement) {

		const pageUrl = url;
		const nodeId = this.getNodeId(pageUrl);
		const imgSrc = localStorage.getItem("image/" + nodeId);

		imgElement.wrap('<a target="_blank" href=' + pageUrl + '></a>');

		if (imgSrc) {
			imgElement.attr("src", imgSrc);
		} else {
			imgElement.attr("src", "/res/loader.gif");
		}

		this.apiRequest('/images/' + this.getFileKey(pageUrl) + '?ids=' + nodeId + '&format=png')
			.then(function (apiResponse) {
				const imageUrl = apiResponse.images[nodeId];

				if (imgSrc != imageUrl) {
					addImageToCanvas(imageUrl, imgElement);
					localStorage.setItem("image/" + nodeId, imageUrl);
				}
			});
	}

	static getFigmaChildren(url, cb) {
		const pageUrl = url;
		const nodeId = this.getNodeId(pageUrl);

		this.apiRequest('/files/' + this.getFileKey(pageUrl) + '/nodes?ids=' + nodeId)
			.then( (res) => {

				// const imageUrl = apiResponse.images[nodeId];
				Object.keys(res.nodes).forEach( node => {
					// console.log(res.nodes[node].document.children);
					const children = res.nodes[node].document.children;

					let imageIDs = [];
					let imageData = {};

					children.map( item => {
						const types = ["FRAME", "COMPONENT", "INSTANCE", "GROUP", "RECTANGLE", "VECTOR"];
						// turn on if you dont' want errthang
						// console.log(item, item.type)
						if (types.includes(item.type)) {
							imageIDs.push(item.id);
							imageData[item.id] = item;
						}
					});

					this.apiRequest('/images/' + this.getFileKey(pageUrl) + '?ids=' + imageIDs.join() + '&format=png')
						.then(function (res) {
							// this makes promises to send back all the data at once
							let promises = []
							Object.keys(res.images).forEach( imageID => {
								// console.log("single", imageID, imageData[imageID]);
								let image = imageData[imageID].absoluteBoundingBox;
								image.id = imageID
								image.url = res.images[imageID];

								return promises.push(new Promise((resolve) => {
									resolve(image);
								}))
							})

							Promise.all(promises).then((results) => {
								// console.log("All done", results, imageData.id);
								let content = {}

								results.map((data) => {
									content[data.id] = data
								})

								cb(content)
							}).catch((e) => {
								// Handle errors here
							});

							// console.log("image response", imageUrl, boundingBox, apiResponse);
						}).catch(function (error) {
							return error;
						});
				});

			});
	}
}

export default Figma;
