import * as THREE from 'three';

import Utils from './Utils.js';
import { DragControls } from "three/examples/jsm/controls/DragControls.js";
import TWEEN from '@tweenjs/tween.js'

const friction = 0.85;
const bounce = 0.2;

let dragging = false;

let position = new THREE.Vector3()
let previous = new THREE.Vector3()
let velocity = new THREE.Vector3()
let clock = new THREE.Clock();
let clockDelta = 0;

class ObjectControls extends DragControls {
	constructor(_objects, _camera, _domElement, _controls) {
		super(_objects, _camera, _domElement);

		this.objects = _objects
		this.controls = _controls
		this.camera = _camera
		this.xz = true
		this.isHovering = null
		this.isLightbox = null


		// console.log("construct", this.objects, _objects, _camera, _domElement)
		// this.objects = []
		// this._objects = this.objects

		this.add = (object) => {
			// console.log("first", this.getObjects())
			this.objects.push(object)
			// console.log("second", this.getObjects())
		}

		// // this.transformGroup = true;
		// // domElement.addEventListener( 'drag', domElement );
		// this.addEventListener( 'pointerdown', (event) => {
		// 	// event.preventDefault();

		// 	console.log("pointer down", event.button, this.xz)
		// 	if (event.button === 0) {
		// 		this.xz = true;
		// 	} else {
		// 		this.xz = false;
		// 	}
		// });

		// _domElement.addEventListener( 'contextmenu', (event) => {
		// 	console.log("right click down", event.button, this.xz)
		// 	event.preventDefault();
		// });

		// _domElement.addEventListener( 'pointerdown', function (event) {
		// 	_domElement.style.cursor = 'grabbing';
		// 	console.log("mouse down")
		// })

		// _domElement.addEventListener( 'pointerup', function (event) {
		// 	_domElement.style.cursor = 'grab';
		// 	console.log("mouse up")
		// })

		let worldPositionStart;

		this.addEventListener( 'dragstart', function (event) {
			// console.log("dragstart", event.object.position)

			// _domElement.style.cursor = "grab !important"
			// console.log("event", event.object, this.getObjects())
			// console.log('drag')
			// console.log("dragstart", this.xz)
			worldPositionStart = event.object.position.clone()
			this.controls.enabled = false;
		});

		this.addEventListener( 'dragend', function(event) {
			const delta = worldPositionStart.distanceTo(event.object.position)
			// console.log("drag", event)

			// disambig click vs drag
			if (delta < 5) {
				this.isLightbox = true
				const objectWorldPosition = event.object.getWorldPosition()

				// console.log("click", event.object, event.object.geometry.parameters.width, event.object.geometry.parameters.height)
				// thank this dude https://stackoverflow.com/questions/14614252/how-to-fit-camera-to-object
				const padding = 0;
				const aspect = window.innerWidth / window.innerHeight
				const scaleFactor = event.object.parent.scale.x
				const w = (event.object.geometry.parameters.width * scaleFactor) + padding;
				const h = (event.object.geometry.parameters.height * scaleFactor) + padding;

				const fovX = this.camera.fov * aspect;
				const fovY = this.camera.fov;

				const distanceX = (w / 2) / Math.tan(Math.PI * fovX / 360) + (w / 2);
				const distanceY = (h / 2) / Math.tan(Math.PI * fovY / 360) + (w / 2);

				const distance = Math.max(distanceX, distanceY);

				// const dist = height / 2 / Math.tan(Math.PI * this.camera.fov / 360);
				// const dist2 = 100
				// const dist = event.object.geometry.parameters.height / 2 / Math.tan(Math.PI * this.camera.fov / 360);
				// const fov = 2 * Math.atan( ( event.object.geometry.parameters.width / aspect ) / ( 2 * dist ) ) * ( 180 / Math.PI ); // in degrees
				// this.camera.fov = fov
				// this.camera.updateProjectionMatrix();


				const newTargetPosition = new THREE.Vector3(objectWorldPosition.x, objectWorldPosition.y, objectWorldPosition.z + 100)
				const newCameraPosition = new THREE.Vector3(objectWorldPosition.x, objectWorldPosition.y, objectWorldPosition.z + distance)

				// console.log('vectors', distance, objectWorldPosition, newCameraPosition)

				this._tweenCamera(newCameraPosition, newTargetPosition)
			} else {
				// position.set(event.object.position.x, event.object.position.y, event.object.position.z)
				// velocity = previous.distanceTo(event.object.position)
				const maxVelocity = new THREE.Vector3(60, 60, 60)
				const minVelocity = maxVelocity.clone().negate()

				const clampedVelocity = velocity.clone().clamp(minVelocity, maxVelocity)
				const displacement = clampedVelocity.clone().multiplyScalar(1000).divideScalar(20)
				// console.log("v", clampedVelocity, maxVelocity)
				const currentPosition = event.object.position.clone()
				// v = pixels / ms
				// vS = pixels / ms * 1000
				const newPosition = currentPosition.clone().add(displacement)


				// cancel if it's still tweening
				if (event.object.tween && event.object.tween.isPlaying()) {
					event.object.tween.stop()
					TWEEN.remove(event.object.tween)
				}

				const tween = new TWEEN.Tween( event.object.position)
					.to( newPosition, 1000 )
					.easing(TWEEN.Easing.Exponential.Out)
					.start();

				event.object.tween = tween
				// console.log("end velocity", velocity, clampedVelocity)
			}

			// console.log("dragend", event.object.position)
			this.controls.enabled = true;

			// this.xz = true;
		});

		this.addEventListener('drag', (event) => {
			_domElement.style.cursor = 'move';

			// console.log("drag", event)
			clockDelta = clock.getDelta()
			position.copy(event.object.position)
			velocity.copy(event.object.position).sub(previous).divideScalar(clockDelta).divideScalar(1000)

			previous.copy(position)

			// lock y
			// if (this.xz) {
			// 	// plane.setFromNormalAndCoplanarPoint( normal, _worldPosition.setFromMatrixPosition( object.matrixWorld ) );
			// 	event.object.position.y = worldPositionStart.y;
			// 	console.log(this.controls.getPolarAngle())
			// }

			if (this.controls.getPolarAngle() < 1) {
				// plane.setFromNormalAndCoplanarPoint( normal, _worldPosition.setFromMatrixPosition( object.matrixWorld ) );
				event.object.position.y = worldPositionStart.y;
				// console.log(this.controls.getPolarAngle())
			}

		});

		this.intersectObjectWithRay = ( object, raycaster, includeInvisible ) => {
			const allIntersections = raycaster.intersectObject( object, true );

			for ( let i = 0; i < allIntersections.length; i ++ ) {
				if ( allIntersections[ i ].object.visible || includeInvisible ) {
					return allIntersections[ i ];
				}
			}

			return false;
		}


		// const draggableObjects = this.getObjects();
		// let objectSelection = null;

		this.addEventListener('hoveron', (event) => {
			this.isHovering = true
		// 	// console.log("hover", draggableObjects, this.getObjects(), objects)
		// 	if (event.object.parent && event.object.parent.name.includes("_object")) {

		// 		objectSelection = event.object.parent
		// 		// console.log(objectSelection)
		// 		// console.log(event.object.name)
		// 	} else if (event.object.parent && event.object.parent.parent && event.object.parent.parent.name.includes("_object")){
		// 		objectSelection = event.object.parent.parent
		// 	} else {
		// 		objectSelection = event.object
		// 	}

		// 	draggableObjects.length = 0;
		// 	this.transformGroup = true;
		// 	group.attach(objectSelection);
		// 	draggableObjects.push(group);

		// 	// console.log("hover", this.getObjects().length, objects)

		});


		this.addEventListener('hoveroff', (event) => {
			this.isHovering = false

			_domElement.style.cursor = "grab !important"

		// 	// reset
		// 	this.transformGroup = false;
		// 	draggableObjects.length = 0;
		// 	// scene.attach(event.object);
		// 	scene.attach(objectSelection);
		// 	draggableObjects.push(...initObjects);
		});

	}

	_tweenCamera = ( newCameraPosition, newTargetPosition, duration ) => {
		// todo: garbage collect tweens and stop tweens if someone is tryna move cam

		// this.controls.enabled = false;
		const cameraPosition = new THREE.Vector3().copy( this.camera.position );
		const targetPosition = new THREE.Vector3().copy( this.controls.target );

		// console.log([cameraPosition, newCameraPosition, targetPosition, newTargetPosition, duration])

		new TWEEN.Tween( cameraPosition, this.tweenCameraGroup )
			.to( newCameraPosition, duration )
			.easing( TWEEN.Easing.Exponential.Out )
			.onUpdate(() => {
				// console.log('tweening', this.camera.fov);
				this.camera.position.copy( cameraPosition );
			})
			.onComplete(() => {
				this.camera.position.copy( newCameraPosition );
			})
			.start();

		new TWEEN.Tween( targetPosition, this.tweenCameraGroup )
			.to( newTargetPosition, duration )
			.easing( TWEEN.Easing.Exponential.Out )
			.onUpdate(() => {
				this.camera.lookAt( targetPosition );
				this.controls.target = targetPosition;
				// if you want cool zoom mapping
				// this.controls.target.y = Utils.mapRange(this.controls.distance(), this.controls.minDistance, this.controls.maxDistance, targetCameraHeight, 0);
			} )
			.onComplete(() => {
				this.camera.lookAt( newTargetPosition );
				// if i want the cool zoom mapping
				// this.controls.target.y = Utils.mapRange(this.controls.distance(), this.controls.minDistance, this.controls.maxDistance, targetCameraHeight, 0);
				this.controls.target = newTargetPosition;
				// this.controls.enabled = true;
				this.controls.update();
			} )
			.start();
	}

}

export default ObjectControls;



// var friction = 0.85;
// var bounce = 0.2;

// var container = document.querySelector( '#container' );
// var handle = document.querySelector( '#handle' );
// var bounds = container.getBoundingClientRect();
// var radius = handle.offsetWidth / 2;

// var dragging = false;
// var mouse = { x: 0, y: 0 };
// var position = { x: 0, y: 0 };
// var previous = { x: position.x, y: position.y }; // in case position is initialised at non-zero values
// var velocity = { x: 0, y: 0 };

// function step() {

//     requestAnimationFrame( step );

//     if ( dragging ) {

//         previous.x = position.x;
//         previous.y = position.y;

//         position.x = mouse.x;
//         position.y = mouse.y;

//         velocity.x = ( position.x - previous.x );
//         velocity.y = ( position.y - previous.y );

//     } else {

//         position.x += velocity.x;
//         position.y += velocity.y;

//         velocity.x *= friction;
//         velocity.y *= friction;
//     }

//     if ( position.x > bounds.width - radius ) {
//         velocity.x *= -BOUNCE;
//         position.x = bounds.width - radius;
//     }

//     if ( position.x < radius ) {
//         velocity.x *= -BOUNCE;
//         position.x = radius;
//     }

//     if ( position.y > bounds.height - radius ) {
//         velocity.y *= -BOUNCE;
//         position.y = bounds.height - radius;
//     }

//     if ( position.y < radius ) {
//         velocity.y *= -BOUNCE;
//         position.y = radius;
//     }

//     // could use css transforms
//     handle.style.left = position.x + 'px';
//     handle.style.top = position.y + 'px';
// }

// // attach to handle instead to init drag only when grabbing the handle
// container.addEventListener( 'mousedown', function() { dragging = true; });
// document.addEventListener( 'mouseup', function() { dragging = false; } );
// document.addEventListener( 'mousemove', function( event ) {
//     mouse.x = event.x - bounds.left;
//     mouse.y = event.y - bounds.top;
// });

// step();