import React from 'react';
import { withRouter } from "react-router-dom";

// components
import API from './components/API.js';
import Utils from './components/Utils.js';

// import MetaTags from './components/MetaTags.js'

import AvatarCreator from './components/AvatarCreator.js';
import Profile from './components/Profile.js';

import Scene from './components/Scene.js';
import * as THREE from 'three';

class AvatarCreation extends React.Component {

	constructor(props)
	{
		super(props);

		this.state ={
			file: null,
			autoRotate: false,//!Utils.isUserAgent("snap"),
			avatarGenerating: false,
			colors: null,
			filteredPeople: {},
			skinValue: null,
			hairValue: null,
			shirtValue: null,
			sliderValue: 0,
			defaultPhoneValue: "Phone number",
			phoneValue: "Phone number",
			status: {
				message: null,
				animation: null
			}
		};

	}

	componentDidMount() {
		// window.addEventListener('scroll', this.listenToScroll)

		this._generateAvatar()

		API.getResource("colors", (data) => {
			// console.log(data.skinColors)
			this.setState({colors: data})
		})
	}

	componentDidUpdate(prevProps, prevStates) {
	}

	componentWillUnmount() {
		// unmount geoposition watch
		console.log("unmount avatar creation");
	}

	render() {

		return (
			<div>
				<div
					id="scene"
					className="full-viewport absolute"
				>
					<Scene
						ref="scene"
						showDebug={this.state.showDebug}
						bgColor={"random"}
						match = {this.props.match}
						people={this.state.filteredPeople}
						parentStatusMethod={this._updateStatus}
						autoRotate={this.state.autoRotate}
					/>
				</div>

				<div className={`flex flex-column mb-r exclusion absolute bottom-0 centerX scaleIn}`}>

					<button
						className="pill-button z2"
						onClick={(e) => {
							e.preventDefault();
							this._toggleBody()
						}}
					>Change Body
					</button>

					<input
						name="skin"
						id="skinInput"
						className="mt3"
						type="range"
						min="0"
						max="100"
						value={this.state.skinValue}
						onChange={this._handleChange}
						step="1"
					/>

					<input
						name="hair"
						id="hairInput"
						className="mt3"
						type="range"
						min="0"
						max="100"
						value={this.state.hairValue}
						onChange={this._handleChange}
						step="1"
					/>

					<input
						name="shirt"
						id="shirtInput"

						className="mt3"
						type="range"
						min="0"
						max="100"
						value={this.state.shirtValue}
						onChange={this._handleChange}
						step="1"
					/>

					<div
						className="sliderContainer mt3"
						onScroll={(e) => {
							e.preventDefault();
							this.setState({sliderValue: e.target.scrollLeft})
							// console.log("scroll", e.target.scrollLeft)
						}}
						style={{
							"width": "318px",
							"overflow": "scroll"
						}}
					>
						<div
							className="bg-white absolute centerX"
							style={{
								"width": "4px",
								"height": "24px",
								"opacity": "1",
								// "borderRadius": "50%"
							}}
						>
						</div>

						<div
							className="white absolute center centerX"
							style={{"marginTop": "-24px"}}
						>
							{this.state.sliderValue}
						</div>

						<div
							className=""
							style={{
								"width": "1800px",
								"opacity": ".2",
								"borderBottom": "8px dotted white",
								"marginTop": "8px"
							}}
						>
						</div>
					</div>

				</div>

				<AvatarCreator
					parentUserMethod={this._filterPeople}
				/>

				<div className="absolute top-0 right-0 pt3 mx3">
					<Profile
						show={true}
						showLogout={true}
						history={this.props.history}
					/>
				</div>
			</div>
		);
	}

	_resetState = () => {
		this.props.history.push('/');
	}

	_updateStatus = (message, animation="fadePulse") => {
		const totalPeople = Object.keys(this.state.filteredPeople).length;

		if (message === "got yo face") {
			this.setState(prevState => ({
				loadedPeople: prevState.loadedPeople + 1
			}), () => {
				// console.log("got you", totalPeople, this.state.loadedPeople)
				// this callback is async and fires too fast sometimes if no avatars are real
				if (totalPeople === this.state.loadedPeople) {
					console.log("say cheese fries", `${this.state.loadedPeople}/${totalPeople}`)
					this.setState({sayCheese: true})
				}
			});
		}

		this.setState({
			status: {
				message: this.state.loadedPeople  + " / " + totalPeople,
				animation: animation
			}
		});

	}

	_filterPeople = (newPeople) => {
		// console.log('filtering people', newPeople)
		// let filteredPeople = {};
		// filteredPeople = !newPeople ? this.state.people : newPeople;
		if (newPeople) {
			this.setState({filteredPeople: newPeople});
		}
	}

	_toggleBody = (target, value) => {
		// const avatarValue = {}
		// avatarValue[target] = value

		console.log("render a", this.refs.scene.state.renderedAvatars)
		this.refs.scene.state.renderedAvatars[Object.keys(this.refs.scene.state.renderedAvatars)[0]].updateBody();
	}


	_updateAvatar = (target, value) => {
		const avatarValue = {}
		avatarValue[target] = value

		this.refs.scene.state.renderedAvatars[Object.keys(this.refs.scene.state.renderedAvatars)[0]].update(avatarValue);
	}

	_generateAvatar = () => {
		let filteredPeople = {}

		API.getCurrentCachedUser((user) => {
			console.log("got", API.getCurrentPhoneNumber(), user)
			if (user) {
				filteredPeople = user;
			} else {
				filteredPeople = API._generateRandomUsers(1);
			}
			this.setState({filteredPeople});
		})
		// console.log("filtered", filteredPeople)
	}

	_handleChange = (event) => {
		let gradient

		if (event.target.name === "shirt") {
			gradient = this.state.colors.shirtColors
		} else if (event.target.name === "skin") {
			gradient = this.state.colors.skinColors
		} else if (event.target.name === "hair") {
			gradient = this.state.colors.skinColors
		}

		let colorRange = []
		gradient.map((value, index) => {
			const prevIndexValue = (100 / (gradient.length-1)) * (index-1)
			const indexValue = (100 / (gradient.length-1)) * (index)

			if (event.target.value >= prevIndexValue && event.target.value <= indexValue) {
				// colorRange = [index-1, index];
				const previousColor = new THREE.Color(parseInt(gradient[index-1]))
				const currentColor = new THREE.Color(parseInt(gradient[index]))
				colorRange = [[previousColor.r * 255, previousColor.g * 255, previousColor.b * 255], [currentColor.r * 255, currentColor.g * 255, currentColor.b * 255]];
			}
		});

		const ratio = (event.target.value % (100 / (gradient.length-1))) / (100 / (gradient.length-1))
        const result = Utils.pickRGB(colorRange[1], colorRange[0], ratio);
        // console.log("change", result)
		this._updateAvatar(event.target.name, result)
	}

}

export default withRouter(AvatarCreation);
