import React from 'react';
import { withRouter } from "react-router-dom";
import API from './components/API.js';
import Utils from './components/Utils.js';
import Values from "./components/Values.js";
import Figma from './components/Figma.js';

// components
import Scene from './components/Scene.js';
import MetaTags from './components/MetaTags.js'
import Profile from './components/Profile.js';

// agora
import AgoraRTC from "agora-rtc-sdk-ng";
AgoraRTC.setLogLevel(4)
import AgoraRTM from 'agora-rtm-sdk'

// assets
import JoinSound from './res/sounds/join.m4a'
import LeaveSound from './res/sounds/leave.m4a'

// facemesh from mediapipe
import FacePipe from './components/FacePipe.js';

class User extends React.Component {

	constructor(props)
	{
		super(props);

		this.state = {
			showDebug: Utils.isLocalhost(),
			autoRotate: false,
			loading: false,

			loadedPeople: 0,
			showInvite: false,
			filteredPeople: {},
			content: {},
			rearFacingCamera: false,
			showRoster: false,
			audioStreams: {},
			videoStreams: {},
			userVolumes: {},
			thumbnailURL: null,
			roster: {},
			urlValue: "Figma URL",
			sayCheese: false,
			title: {
				name: null,
				animation: null
			},
			muted: API.getMutedState(),
			mutedVideo: API.getMutedVideoState(),
			meta: {},
			status: {
				message: null,
				animation: null
			}
		};

		this.rtc = {
		  // For the local client.
		  client: AgoraRTC.createClient({ mode: "rtc", codec: "vp8" }),
		  // For the local audio track.
		  localAudioTrack: null,
		  localVideoTrack: null
		};


		this.rtm = {
		  // For the local client.
		  client: null,
		  lobby: null,
		  channel: null
		  // For the local audio track.
		  // localAudioTrack: null,
		};

		this.faceInterval = null;
		window.prerenderReady = false;
	}

	componentDidMount() {
		// console.log("props", this.props.match.params.id)
		// this._addRandomPeople(Utils.randomNumber(1, 4, true));
		this._startCall();
		this.beforeUnloadListener()

		// gen random content
		this._getRandomContent()

		if (API.getLocalStorage("figmaURL")) {
			this.setState({urlValue: API.getLocalStorage("figmaURL")})
		}

	    document.addEventListener("keyup", this._handleKeyUp);
	}

	componentDidUpdate(prevProps, prevStates) {
		// for some reason this doesn't fire
		if (this.props.match.params.id !== prevProps.match.params.id) {
			console.log('props fires', this.props.match.params.id)
			this._joinChannel()
			// todo remap people on and off

			// this._leaveCall(() => {
			// 	// clear avatars not part of this id
			// 	this._cleanUpAvatars()
			// 	this._startCall();
			// })
		}

		if (this.state.filteredPeople && this.state.filteredPeople !== prevStates.filteredPeople) {
			// need to handle avatars poppin in and out
			// console.log("people change")
		}
	}

	componentWillUnmount() {
		console.log("home unmounting");

		this._leaveCall()
		this.rtm.client.logout()

		this.refs.scene.stop();
		document.removeEventListener("keyup", this._handleKeyUp);
	}

	render() {

		return (
			<div>
				<MetaTags data={this.state.meta} />

				<div
					id="scene"
					className="full-viewport absolute"
				>
					<Scene
						ref="scene"
						showDebug={this.state.showDebug}
						backgroundColor="random"
						match={this.props.match}
						people={this.state.filteredPeople}
						content={this.state.content}
						autoRotate={this.state.autoRotate}
						parentStatusMethod={this._updateStatus}
					/>
					<div
						id="avatarLabels"
						className="absolute center top-0 left-0 white h3"
					/>
				</div>


				{ this.props.match.params.id ?
					<div
						className="absolute fit-content top-0 exclusion pt3 left-0 mx3 pointer hover"
						onClick={() => {
							this._goBackToLobby();
						}}
					>
						Back to Lobby
					</div>
					: null
				}

				{ !this.state.mutedVideo ?
					<FacePipe
						scene={this.refs.scene}
					/>
					: null
				}

				<div
					className={`absolute flex justify-between items-center full-width top-0 ${Utils.isMobile() ? "mt1 px1" : "mt2 px3"}`}
				>
					<div>
						<button
							className={`pill-button border border-red m1 scaleIn exclusion`}
						>
							<i className={`material-icons pr1`}>logout</i>
							leave
						</button>
					</div>

					<div className="exclusion">
						<span
							className="px1"
						>
							{ this.props.match.params.id ? "1v1 w/" : "Lobby" }
						</span>

						{ this.state.filteredPeople && this.state.audioStreams ?
							Object.keys(this.state.filteredPeople).map((key, i, array) => {
								return (
									<span key={key}>
										{ key !== API.getCurrentPhoneNumber() ?
											<span
												className="px1 pointer hover"
												onClick={() => {
													this._sendMessage(key);
												}}
											>
												{this.state.filteredPeople[key] ? this.state.filteredPeople[key].firstName : ""}
												<i
													className={`material-icons hover white px1 ${this.state.userVolumes[key] > .01 || !this.state.audioStreams[key] ? "scaleIn" : "scaleOut"}`}
													style={{fontSize: "14pt"}}
												>
													{`${!this.state.audioStreams[key] ? "mic_off" : "graphic_eq"}`}
												</i>
											</span>
										: null
										}
									</span>
								)
							})
							: null
						}
					</div>

					<Profile
						show={true}
						showLogout={true}
						history={this.props.history}
					/>
				</div>

				<div
					className={`absolute flex justify-between full-width nowrap bottom-0 exclusion ${Utils.isMobile() ? "mb0 px1" : "mb2 px3"}`}
				>
					<div>
						<button
							className={`pill-button border border-red m1 scaleIn`}
							onClick={() => {
								this._toggleMute();
							}}
						>
							<i className={`material-icons pr1`}>{this.state.muted ? "mic_off" : "mic_none"}</i>
							{this.state.muted ? "muted" : "mute"}
						</button>

						<button
							className={`circle-button border border-red m1 scaleIn`}
							onClick={() => {
								this._toggleMuteVideo();
							}}
						>
							<i className={`material-icons`}>{this.state.mutedVideo ? "videocam_off" : "videocam"}</i>
						</button>
					</div>

					<div>
						<button
							className={`circle-button border border-red m1 scaleIn`}
							onClick={() => {
								// console.log("toggle env")
								this.refs.scene.toggleBackground();
							}}
						>
							<i className={`material-icons`}>landscape</i>
						</button>

						<button
							className={`circle-button border border-red m1 scaleIn`}
							onClick={() => {
								this._getRandomContent();
							}}
						>
							<i className={`material-icons`}>add</i>
						</button>

						<i className={`material-icons m1 ${this.state.loading ? "scaleIn spin" : "scaleOut display-none"}`}>hourglass_top</i>
					</div>

					<div>
						<input
							className={`pill button-text m1 ${!this.state.loading ? "scaleIn" : "scaleOut display-none"}`}
							style={{width: "180px"}}
							type="text"
							name="Figma URL"
							value={this.state.urlValue}
							onFocus={ this._onURLFocus }
							onBlur={ this._onURLBlur }
							onChange={this._onURLChange}
						/>
					</div>
				</div>

				{ this.state.sayCheese ?
					<div id='url2png-cheese' className="absolute z4 white h0 bg-red"></div>
					:
					null
				}

				{
					Utils.isMobile() ?
						<button
							className={`absolute right-0 hover white p3`}
							onClick={ () => {
								this.refs.scene.toggleOrientation();
								this.setState({ rearFacingCamera: !this.state.rearFacingCamera })
							}}
						>
							<i className={`material-icons scaleIn`}>{this.state.rearFacingCamera ? "touch_app" : "360"}</i>
						</button>
					: null
				}

			</div>
		);
	}

	_playJoinTrack = () => {
		const track = new Audio(JoinSound)

		track.play().then((data) => {
			// console.log("audio silent success", data)
			// console.log("audio silent success", data)
		}).catch((error) => {
			console.log("sound error", error)
		})
	}

	_playLeaveTrack = () => {
		const track = new Audio(LeaveSound)

		track.play().catch((error) => {
			console.log("sound error", error)
		})
	}

	_toggleInvite() {
		const toggleState = this.state.showInvite ? false : true;
		this.setState({showInvite: toggleState});
	}

	_updateStatus = (message, animation="fadePulse") => {
		const totalPeople = Object.keys(this.state.filteredPeople).length;

		if (message === "got yo face") {
			this.setState(prevState => ({
				loadedPeople: prevState.loadedPeople + 1
			}), () => {
				// console.log("got you", totalPeople, this.state.loadedPeople)
				// this callback is async and fires too fast sometimes if no avatars are real
				if (totalPeople === this.state.loadedPeople) {
					console.log("say cheese fries", `${this.state.loadedPeople}/${totalPeople}`)
					this.setState({sayCheese: true})
				}
			});

		}

		this.setState({
			status: {
				message: this.state.loadedPeople  + " / " + totalPeople,
				animation: animation
			}
		});
	}

	_cleanUpAvatars = () => {
		// super hacks to cleanup
		let newPeople = {}

		if (this.props.match.params.id) {
			newPeople[this.props.match.params.id] = this.state.filteredPeople[this.props.match.params.id]
		}

		newPeople[API.getCurrentPhoneNumber()] = this.state.filteredPeople[API.getCurrentPhoneNumber()]

		this.setState({filteredPeople: newPeople});
	}

	_filterPeople = (newPeople) => {
		// console.log('filtering people', newPeople)
		// let filteredPeople = {};
		// filteredPeople = !newPeople ? this.state.people : newPeople;
		if (newPeople) {
			this.setState({filteredPeople: newPeople});
		}
	}

	_addRandomPeople = (randomPeople) => {
		if (randomPeople) {
			let filteredPeople = {
				...this.state.filteredPeople,
				...API._generateRandomUsers(randomPeople)
			};

			this.setState({filteredPeople});
		}
	}

	_updateInvites = (newPeople) => {
		console.log('update people', newPeople, this.state.filteredPeople)
		if (newPeople) {
			API.addInviteToRoom(this.props.match.params.id, newPeople);

			let filteredPeople = {
				...this.state.filteredPeople,
				...newPeople
			}

			this.setState({
				filteredPeople: filteredPeople
			});

			console.log('final people', newPeople, filteredPeople, this.state.filteredPeople)
		}
	}

	_removePerson = (newPersonID) => {
		// get roster
		const newFilteredPeople = {...this.state.filteredPeople};

		delete newFilteredPeople[newPersonID]
		this.setState({filteredPeople: newFilteredPeople})
	}

	_addPerson = (newPersonID) => {
		const isGuest = newPersonID.includes("guest")
		// get roster
		let newFilteredPeople = {};

		const userPromise = new Promise((resolve, reject) => {
			console.log("adding a person", newPersonID, isGuest)

			if (isGuest) {
				newFilteredPeople[newPersonID] = API.getGuest(newPersonID);
				resolve()
			} else {
				API.getUser(newPersonID, (userData) => {
					if (userData) {
						newFilteredPeople[newPersonID] = userData;
					}
					resolve();
				})
			}
		})

		Promise.all([userPromise]).then( async () => {
			if (newPersonID !== API.getCurrentPhoneNumber() && this.rtm.client) {
				const currentChannel = this.props.match.params.id && this.rtm.client ? this.rtm.channel : this.rtm.lobby
				const peopleInChannel = await currentChannel.getMembers()

				if (!peopleInChannel.includes(newPersonID)) {
					newFilteredPeople[newPersonID].transparent = true;
					// console.log(newPersonID, "is transparent", newFilteredPeople[newPersonID])
				}
			}

			// merge new people with old people
			const filteredPeople = {
				...this.state.filteredPeople,
				...newFilteredPeople
			}
			// console.log("user promise ended", filteredPeople)
			this.setState({filteredPeople});

			const rosterTitle = Object.keys(filteredPeople).map(user => filteredPeople[user] ? filteredPeople[user].firstName : null);
			// set meta data
			this.setState({
				meta: {
					title: `Tele AR w/ ${rosterTitle.join(', ')}`
				}
			}, () => {
				window.prerenderReady = true;
			})
		});

	}

	// agora
	// https://docs.agora.io/en/Voice/start_call_audio_web_ng?platform=Web
	_startCall = (channelID="lobby") => {
		console.log("joining", channelID)
		// console.log('logger', AgoraRTC.VERSION);
		const options = {
			// Pass your app ID here.
			appID: "d46704c8936c457793015316aa6fa8d1",
			// Set the channel name.
			channel: channelID,
			// Pass a token if your project enables the App Certificate.
			token: null, //"00630a2f9c1441d42ecb04ec9cc3e0a2e58IAAJgBIvx2MHnb085ihEh+QUxlU1YNISfKC+ha6WJ+zyRfdV5MwAAAAAEACZqwdI8lb2XwEAAQDxVvZf",
			uid: API.getCurrentPhoneNumber() //The user ID, which should be unique in a channel
		};

		// rtm
		// init rtm if it doesn't exist
		if (!this.rtm.client) {
			console.warn("rtm init")
			this.rtm.client = AgoraRTM.createInstance(options.appID, {logFilter: "LOG_FILTER_WARNING"});

			// this.rtm.client.on('ConnectionStateChanged', (newState, reason) => {
			// 	console.log('on connection state changed to ' + newState + ' reason: ' + reason);
			// });

			this.rtm.client.login({ token: options.token, uid: options.uid }).then(() => {
				console.warn('rtm login success');

				this.rtm.client.on('MessageFromPeer', ({ text }, peerID) => { // text: text of the received message; peerId: User ID of the sender.
					/* Your code for handling the event of receiving a peer-to-peer message. */
					console.log('got message', text, peerID)

					if (text) {
						this.props.history.push(`${text}`)
					}
				});

				this._joinChannel()
			}).catch(err => {
				console.log('rtm login failure', err);
			});

		}

		// rtc
		const joinPromise = this.rtc.client.join(options.appID, options.channel, options.token, options.uid);

		joinPromise.then( async (uid) => {
			// add avatars for users
			this._addPerson(uid)
			this._detectFaces()

			// handle remote joins and leaves
			this.rtc.client.on("user-joined", async (user) => {
				// console.log('join', user.uid)
				this._addPerson(user.uid)
				this._playJoinTrack()
			})

			this.rtc.client.on("user-left", async (user) => {
				// console.log('left', user.uid)
				this._removePerson(user.uid)
				this._playLeaveTrack()
			})

			// handle audio tracks
			// Create an audio track from the audio sampled by a microphone.
			this.rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
			this.rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack({
				encoderConfig: {
					width: 240,
					// Specify a value range and an ideal value
					height: { ideal: 240, min: 96, max: 320 },
					// frameRate: 15,
					// bitrateMin: 600, bitrateMax: 1000,
				}

			});

			// Publish the local audio track to the channel.
			this.rtc.client.publish([this.rtc.localAudioTrack, this.rtc.localVideoTrack]).then(() => {
				console.log("publish audio for", uid);
				this._mute(this.state.muted);
				this._muteVideo(this.state.mutedVideo);
				this._addAudioStream(uid, this.rtc.localAudioTrack)
				this._addVideoStream(uid, this.rtc.localVideoTrack)
			})

			// handle remote audio tracks published
			this.rtc.client.on("user-published", async (user, mediaType) => {
				await this.rtc.client.subscribe(user, mediaType);
				// console.log("subscribe success", user.uid, user, mediaType);

				// If the subscribed track is audio play it.
				if (mediaType === "audio") {
					const remoteAudioTrack = user.audioTrack;
					this._addAudioStream(user.uid, remoteAudioTrack)
				}

				if (mediaType === "video") {
					const remoteVideoTrack = user.videoTrack;
					this._addVideoStream(user.uid, remoteVideoTrack)
				}
			});

			// destroy on unpublish event
			this.rtc.client.on("user-unpublished", (user, mediaType) => {
				console.log("user-unpublished", user.uid)
				this._removeAudioStream(user.uid)
				this._removeVideoStream(user.uid)

				// seems like this auto removes tracks
				// if (mediaType === "audio") {
					// console.log("unpub", user)
				// }
			});

		}).catch((error) => {
			// this could have same uid errors
			console.log("join error", error, options.uid)
		})
	}

	// leave rtc
	_leaveCall = async (cb=null) => {
		// Destroy the local audio and track.
		console.log('leave myselfs')
		if (this.faceInterval) {
			clearInterval(this.faceInterval);
		}

		if (this.rtc.localAudioTrack) {
			this.rtc.localAudioTrack.close();
		}

		if (this.rtc.localVideoTrack) {
			this.rtc.localVideoTrack.close();
		}

		// Leave the channel.
		await this.rtc.client.leave();

		// need to remove all listeners on rtm with removeAllListeners
		if (cb) {
			cb()
		}
	}


	async _addVideoStream(uid, stream) {
		// this holds all audio streams including yours
		let videoStreams = this.state.videoStreams;

		videoStreams[uid] = {
			id: uid,
			stream: stream
		}
		this.setState({videoStreams});
	}

	async _addAudioStream(uid, stream) {
		// this holds all audio streams including yours
		let audioStreams = this.state.audioStreams;

		audioStreams[uid] = {
			id: uid,
			stream: stream
		}
		this.setState({audioStreams});

		if (uid !== API.getCurrentPhoneNumber()) {
			const currentChannel = this.props.match.params.id ? this.rtm.channel : this.rtm.lobby
			const peopleInChannel = await currentChannel.getMembers()

			if (peopleInChannel.includes(uid)) {
				this._setUserAudioChannel(uid, true)
			} else {
				this._setUserAudioChannel(uid, false)
			}

			stream.play();
		}

		// console.log("add audio", this.state.audioStreams)
	}

	_removeAudioStream(uid) {
		let audioStreams = this.state.audioStreams;
		delete audioStreams[uid];
		this.setState({audioStreams});
		// console.log("remove audio", this.state.audioStreams)
	}

	_removeVideoStream(uid) {
		let videoStreams = this.state.videoStreams;
		delete videoStreams[uid];
		this.setState({videoStreams});
		// console.log("remove audio", this.state.audioStreams)
	}

	_detectFaces() {
		this.faceInterval = setInterval(() => {
			if (this.state.filteredPeople && this.state.audioStreams && this.state.videoStreams && this.refs.scene) {
				const userVolumes = {}
				Object.keys(this.state.filteredPeople).forEach((key, index) => {
					// check if it has a audio stream
					let isTalking = null
					let audioState = null
					let videoStream = null
					let volumeLevel = null
					if (this.state.audioStreams[key]) {
						// have to call each time to get realtime volumes
						volumeLevel = Utils.round(this.state.audioStreams[key].stream.getVolumeLevel(), 2)
						isTalking = volumeLevel > 0 ? true : false

						if (key === API.getCurrentPhoneNumber() && this.state.muted) {
							audioState = "muted"
						} else  {
							audioState = volumeLevel > 0 ? "talking" : null
						}
						// const stats = this.state.audioStreams[key].stream.getStats()
					} else {
						// no track so assume he's muted if avatar still exists
						isTalking = false
						audioState = "muted"
					}

					if (this.state.videoStreams[key] && this.state.videoStreams[key].stream && this.state.videoStreams[key].stream._enabled) {
						videoStream = this.state.videoStreams[key].stream
					}

					userVolumes[key] = volumeLevel
					this.refs.scene.setTalkingAvatar(key, isTalking);
					this.refs.scene.setAvatarLabelState(key, audioState, videoStream);
					this.setState({userVolumes})
					// console.log("detect avatar label", videoStream.isPlaying, videoStream.muted, videoStream.enabled)
					// console.log("vol", key, volumeLevel, isTalking)
					// console.log(this.state.filteredPeople[key].firstName, audioState, volumeLevel)
				})
			}
		}, 500)
	}

	_goBackToLobby() {
		// console.log(this.rtc.client.remoteUsers[0])
		if (this.rtc.client.remoteUsers[0]) {
			this._sendMessage(this.rtc.client.remoteUsers[0].uid, "lobby")
		} else {
			this.props.history.push('/lobby')
		}

	}

	_sendMessage(peerUID, channel) {
		console.log("send", peerUID, channel)
		let url = null
		if (channel === "lobby") {
			url = `/lobby`
		} else {
			const myUID = API.getCurrentPhoneNumber()
			url = `/lobby/${myUID}`
		}

		this.rtm.client.sendMessageToPeer(
			{
				text: url // An RtmMessage object.
			},
			peerUID, // The user ID of the remote user.
		).then(sendResult => {
			this.props.history.push(`${url}`)

			if (sendResult.hasPeerReceived) {
				/* Your code for handling the event that the remote user receives the message. */
				// should set loading state
			} else {
				/* Your code for handling the event that the message is received by the server but the remote user cannot be reached. */
				// should set error state or reset to lobby
			}
		}).catch(error => {
			console.log(error)
			/* Your code for handling the event of a message send failure. */
			// should set error state or reset to lobby
		});
	}

	_mute(muteState) {
		if (this.rtc.localAudioTrack) {
			// console.log("muted?", muteState)
			// in agora v4 muteAudio() is no longer a thing, instead you should
			// disable audio track, however this takes time to publish and subscribe
			// tracks which makes muting super async. maybe we shoudl do setVolume again
			// this.rtc.localAudioTrack.setVolume(!muteState ? 100 : 0)

			// muteState is opposite of setEnabled ie: isMuted true = setEnabled False
			this.rtc.localAudioTrack.setEnabled(!muteState)

			if (muteState) {
				this._removeAudioStream(API.getCurrentPhoneNumber())
			} else {
				this._addAudioStream(API.getCurrentPhoneNumber(), this.rtc.localAudioTrack)
			}
		}

		API.setMutedState(muteState);
		this.setState({muted: muteState});
	}

	_toggleMuteVideo() {
		this._muteVideo(!this.state.mutedVideo);
	}

	_toggleMute() {
		this._mute(!this.state.muted);
	}

	_muteVideo(muteVideoState) {
		if (this.rtc.localVideoTrack) {
			// console.log("muted?", muteState)
			// in agora v4 muteAudio() is no longer a thing, instead you should
			// disable audio track, however this takes time to publish and subscribe
			// tracks which makes muting super async. maybe we shoudl do setVolume again
			// this.rtc.localAudioTrack.setVolume(!muteState ? 100 : 0)

			// muteState is opposite of setEnabled ie: isMuted true = setEnabled False
			this.rtc.localVideoTrack.setEnabled(!muteVideoState)

			if (muteVideoState) {
				this._removeVideoStream(API.getCurrentPhoneNumber())
			} else {
				this._addVideoStream(API.getCurrentPhoneNumber(), this.rtc.localVideoTrack)
			}
		}

		API.setMutedVideoState(muteVideoState);
		this.setState({mutedVideo: muteVideoState});
	}

	_toggleMute() {
		this._mute(!this.state.muted);
	}

	_getFigma(data) {
		// todo: error handle urls
		const url = data ? data : "https://www.figma.com/file/ll6ctQJOKbD5ngfPFSr2qG/Untitled?node-id=0%3A1"
		// data.includes("www.figma.com")
		this.setState({loading: true})

		Figma.getFigmaChildren(url, (content) => {
			this.setState({content})
			this.setState({loading: false})
			API.setLocalStorage("figmaURL", url)
		});
	}

	_joinChannel = () => {
		if (!this.rtm.client) { return }

		if (!this.rtm.lobby) {
			this.rtm.lobby = this.rtm.client.createChannel('lobby'); // Pass your channel ID here.
		}

		let currentChannel = null

		// join lobby or channel
		if (this.props.match.params.id) {
			// leave the lobby
			this.rtm.lobby.leave()

			this.rtm.channel = this.rtm.client.createChannel(this.props.match.params.id)
			currentChannel = this.rtm.channel
		} else {
			// leave a channel if exists
			if (this.rtm.channel) {
				this.rtm.channel.leave().then(()=> {
					return this.rtm.channel = null
				})
			}

			currentChannel = this.rtm.lobby
		}

		// handle channel or lobby join and audio
		currentChannel.join().then( async() => {
			// listen to channel events
			currentChannel.on('MemberJoined', memberID => {
				console.log("channel member joined", memberID)
				this._setUserAudioChannel(memberID, true)
				this._setUserAvatar(memberID, true)
			});

			currentChannel.on('MemberLeft', memberID => {
				console.log("channel member left channel", memberID)
				this._setUserAudioChannel(memberID, false)
				this._setUserAvatar(memberID, false)
				// if someone joins or leaves set their audio channel
			});

			// this.rtm.lobby.on('ChannelMessage', ({ text }, senderID) => {
			// 	console.log("channel message to lobby", text, senderID)
			// });

			// init members on join
			const peopleInChannel = await currentChannel.getMembers()
			// console.log("peopleinchannel", peopleInChannel)
			this._mapAudioChannels(peopleInChannel)
			this._updateAvatars(peopleInChannel)

		}).catch(error => {

		});


		// this.setState({filteredPeople: newPeople});
	}

	_mapAudioChannels = async (peopleInChannel) => {
		// looping through audiostreams but i can see why we may want to loop through
		// filtered people instead since someone can be on mute and then unmuted
		Object.keys(this.state.audioStreams).forEach((uid) => {
			// if it isn't me either
			if (uid !== API.getCurrentPhoneNumber() && peopleInChannel.includes(uid)) {
				// console.log("member in in channel and isnt me", uid, peopleInChannel.includes(uid))
				this._setUserAudioChannel(uid, true)
			} else if (!peopleInChannel[uid]) {
				// console.log("member isnt in channel", uid, peopleInChannel.includes(uid))
				this._setUserAudioChannel(uid, false)
			}
		})
	}

	_updateAvatars = (peopleInChannel) => {
		// console.log("update avatars called", peopleInChannel, this.state.filteredPeople)
		let newPeople = {}

		Object.keys(this.state.filteredPeople).map((uid) => {
			// console.log("does this person exist", uid, personInChannel)
			if (peopleInChannel.includes(uid)) {
				// console.log(uid, "is opaque", this.state.filteredPeople)
				newPeople[uid] = {...this.state.filteredPeople[uid], transparent: false};
			} else {
				// console.log(uid, "is transparent", this.state.filteredPeople)
				newPeople[uid] = {...this.state.filteredPeople[uid], transparent: true};
			}
		})

		// setting this will do infinite prop loop if listening to filtered change
		this.setState({filteredPeople: newPeople});
	}

	_setUserAudioChannel = (uid, muted=false) => {
		// people not in channel is opposite of this
		const volumeLevel = muted ? 0 : 100

		if (this.state.audioStreams[uid]) {
			this.state.audioStreams[uid].stream.setVolume(volumeLevel)
		}
	}

	_setUserAvatar = (uid, visible=true) => {
		if (this.state.filteredPeople[uid]) {
			let newPeopleUpdates = this.state.filteredPeople
			newPeopleUpdates[uid].transparent = visible;
			this.setState({newPeopleUpdates});
		}
	}

	_onURLFocus = (event) => {
		// console.log("focus", event.target.name, event.target.value, this.state.defaultPhoneValue)
		if (event.target.value === event.target.name) {
			this.setState({
				urlValue: ""
			})
		}
	}

	_onURLBlur = (event) => {
		// console.log("unfocus", event.target.value, this.state.defaultPhoneValue)
		if (event.target.value === "") {
			this.setState({
				urlValue: event.target.name
			})
		}
	}

	_onURLChange = (event) => {
		this.setState({urlValue: event.target.value});
	}

	_handleKeyUp = (event) => {
		// for other devs who might not know keyCodes
		switch(event.keyCode) {
			case 13:
				// console.log("hit enter", this.state.currentState)
				this._getFigma(this.state.urlValue)
				break;
			default:
				break;
		}
	}

	_getRandomContent = () => {
		// gen random content
		API.generateRandomContent(Utils.randomNumber(5, 10, true), (randomContent) => {
			this.setState({content: randomContent})
		})
	}


    // Setup the `beforeunload` event listener
    beforeUnloadListener = () => {
		window.addEventListener("beforeunload", (ev) => {
			ev.preventDefault();
			console.log("unloading")
			this._leaveCall()
			this.rtm.client.logout()
			return
		});
	};
}

export default withRouter(User);
