import * as React from 'react';
import { Auth, Provider, RecaptchaVerifier } from './components/Firebase';
import { withRouter } from "react-router-dom";
import {Helmet} from "react-helmet";

import API from './components/API.js';
import Utils from "./components/Utils.js";
import Scene from './components/Scene.js';
import Splash from './res/tele.glb'

class Login extends React.Component {
	constructor() {
		super();

		this.state = {
			currentState: null,
			currentItem: '',
			username: '',
			items: [],
			isEntering: false,
			phoneValue: "Phone number",
			verifyCodeValue: "Verify Code",
			user: null
		}

		this.loginWithPhone = this.loginWithPhone.bind(this);
		this.loginWithGoogle = this.loginWithGoogle.bind(this);

		this.bgColor = Utils.isNight() ? "bg-black" : null;
		// console.log(this.bgColor);
	}

	componentDidMount() {
		this.setState({ isEntering: true });
		let userPending = JSON.parse( localStorage.getItem("userPending") );

		if (userPending) {
			this.setState({ currentState: "loading" });
		}

		window.recaptchaVerifier = new RecaptchaVerifier(this.recaptcha, {
			'size': 'invisible',
			'callback': (response) => {
				console.log("auto captcha")
				// onSignInSubmit();
				// reCAPTCHA solved, allow signInWithPhoneNumber.
				// this.setState('')
			},
			'expired-callback': () => {
				console.log("cant auto captcha")
				// Response expired. Ask user to solve reCAPTCHA again.
			}
		});

		// window.recaptchaVerifier.render().then((widgetId) => {
		// 	window.recaptchaWidgetId = widgetId;
		// });

		Auth.onAuthStateChanged((user) => {
			// todo: we should send someone to home screen
			// and load faster first
			// console.log('auth changed', user.displayName);
			localStorage.removeItem('userPending');
			if (user) {
				API.setCurrentUserData(user)
				const redirectURL = this.props.match.params.id ? this.props.match.params.id : "";
		        this.props.history.push(`/${redirectURL}`);
			} else {
				console.log('no user')
			}
		});

	    document.addEventListener("keyup", this._handleKeyUp);
	}

	componentWillUnmount() {
		document.removeEventListener("keyup", this._handleKeyUp);
	}

	render() {
		return (

			<div
				className={`flex items-center justify-center flex-column ${Utils.isNight() ? "bg-night" : null }`}
				style={{
					height: "100vh"
				}}
			>
				<Helmet>
					<title>Tele</title>
					<meta name="description" content="" />
				</Helmet>

				<div
					className="full-viewport absolute"
				>
					<Scene
						match = {this.props.match}
						ref="scene"
						bg = {Splash}
					/>
				</div>

				<div
					className="flex red z1 absolute exclusion"
					style={{
						fontSize: '37.5vw',
						fontWeight: '500',
						opacity: .95
					}}
				>
				</div>

				<div ref={(ref)=>this.recaptcha=ref}></div>

				<div className={`flex flex-column mb-r exclusion absolute bottom-0`}>

					{ this.state.currentState === "loading" ?
						<h2 className="exclusion normal fadePulse">Hang tight...</h2>
					: this.state.currentState === "showProviders" ?
						<div>
							<button className="pill-button z2 mb4" onClick={this.loginWithPhone}>Log In w/ Phone</button>
							<button className="pill-button z2 mb4" onClick={this.loginWithGoogle}>Log In w/ Google</button>
						</div>
					: this.state.currentState === "verifyCode" ?
						<div className="scaleIn flex flex-column">
							<input
								className="scaleIn pill button-text mb3"
								type="text"
								name="Verify Code"
								value={this.state.verifyCodeValue}
								onFocus={ this.onVerifyCodeFocus }
								onBlur={ this.onVerifyCodeBlur }
								onChange={this.onVerifyCodeChange}
							/>

							<button
								className="col-12 pill-button z2 mb4"
								onClick={this.verifyCode}>
								VerifyCode
							</button>
						</div>
					:
						<div className="flex flex-column scaleIn">
							<input
								className="scaleIn pill button-text mb3"
								type="text"
								name="Phone number"
								value={this.state.phoneValue}
								onFocus={ this.onPhoneFocus }
								onBlur={ this.onPhoneBlur }
								onChange={this.onPhoneChange}
							/>
							<button
								className="scaleIn pill-button z2 mb4"
								onClick={this.signInWithPhone}>
								Next
							</button>
						</div>
					}
				</div>
			</div>

		);
	}

	onVerifyCodeFocus = (event) => {
		// console.log("focus", event.target.name, event.target.value, this.state.defaultPhoneValue)
		if (event.target.value === event.target.name) {
			this.setState({
				verifyCodeValue: ""
			})
		}
	}

	onVerifyCodeBlur = (event) => {
		// console.log("unfocus", event.target.value, this.state.defaultPhoneValue)
		if (event.target.value === "") {
			this.setState({
				verifyCodeValue: event.target.name
			})
		}
	}

	onVerifyCodeChange = (event) => {
		this.setState({verifyCodeValue: event.target.value});
	}

	onPhoneFocus = (event) => {
		// console.log("focus", event.target.name, event.target.value, this.state.defaultPhoneValue)
		if (event.target.value === event.target.name) {
			this.setState({
				phoneValue: ""
			})
		}
	}

	onPhoneBlur = (event) => {
		// console.log("unfocus", event.target.value, this.state.defaultPhoneValue)
		if (event.target.value === "") {
			this.setState({
				phoneValue: event.target.name
			})
		}
	}

	onPhoneChange = (event) => {
		this.setState({phoneValue: event.target.value});
	}

	_startCaptcha() {
		// console.log("cap", new RecaptchaVerifier('recaptcha-container'))
		// return new RecaptchaVerifier('recaptcha-container');
	}

	handleChange(e) {
		/* ... */
	}

	loginWithPhone() {
		this.setState({currentState: "enterPhoneNumber"})
	}

	signInWithPhone = () => {
		// console.log('localstorage', currentUser)
		const phoneNumberValue = API.formatPhoneNumber(this.state.phoneValue)

		// console.log(this.state.phoneValue)
		Auth.signInWithPhoneNumber(phoneNumberValue, window.recaptchaVerifier).then((confirmationResult) => {
			// SMS sent. Prompt user to type the code from the message, then sign the
			// user in with confirmationResult.confirm(code).
			window.confirmationResult = confirmationResult;
			this.setState({currentState: "verifyCode"})
			// console.log("confirm", confirmationResult)
		}).catch((error) => {
			// Error; SMS not sent try restting
			console.log("error", error)
			// window.recaptchaVerifier.render().then((widgetId) => {
			//   grecaptcha.reset(widgetId);
			// })
		});
	}

	verifyCode = () => {
		// const code = "555555";
		localStorage.setItem("userPending", JSON.stringify(true));
		confirmationResult.confirm(this.state.verifyCodeValue).then((result) => {
		  // User signed in successfully.
			const user = result.user;
			// console.log("verified", user)
			localStorage.removeItem('userPending');
		}).catch((error) => {
			// User couldn't sign in (bad verification code?)
			console.log("verify error", error)
			localStorage.removeItem('userPending');
		});
	}

	loginWithGoogle() {
		localStorage.setItem("userPending", JSON.stringify(true));

		// google login
		Auth.signInWithRedirect(Provider).then((result) => {
			const user = result.user;
			this.setState({user});
		});
	}

	_handleKeyUp = (event) => {
		// for other devs who might not know keyCodes
		switch(event.keyCode) {
			case 13:
				// console.log("hit enter", this.state.currentState)
				if (this.state.currentState === "verifyCode") {
					this.verifyCode()
				} else{
					this.signInWithPhone()
				}
				break;
			default:
				break;
		}
	}
}
export default withRouter(Login);
