// https://developer.apple.com/documentation/arkit/arfaceanchor/blendshapelocation
import React from "react";
import ReactDOM from "react-dom";

// import { Database } from './Firebase';
import * as THREE from 'three';
import AvatarSDK from './AvatarSDK.js';
import Utils from './Utils.js';
import TWEEN from '@tweenjs/tween.js'
import API from "./API.js";
import AvatarLabel from "./AvatarLabel.js";

import glasses from '../res/glasses.glb'

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import FresnelShader from "./FresnelShader.js";

import male_idle from '../res/male_idle_generic_lod.glb'
import male_alt from '../res/male_rig_alt.glb'
import female_idle from '../res/female_idle_generic_lod.glb'
import female_alt from '../res/female_rig_alt.glb'

const avatarBodies = [male_idle, male_alt, female_idle, female_alt]
let avatarBodyCycle = Utils.cycle(avatarBodies)

const tweenDuration = 1600;
const tweenDelay = 3500;

const loader = new GLTFLoader();

class SceneAvatar {
	constructor(personData, statusMethod) {
		this.personData = personData || null;
		this.bodyType = personData.avatar && personData.avatar.bodyType === 1 ? female_idle : male_idle;

		this.shader = true;
		this.materials = {};

		this.avatarHead = null;
		this.defaultHead = null;

		// meshes
		this.bodyGroup = new THREE.Group();
		this.body = null;
		this.glasses = null;
		this.glassesPosition = [0, 0, 10]
		this.modelInfo = null;

		// bones
		this.hips = null;
		this.spine = null;
		this.neck = null;
		this.head = null;

		this.skin = null;
		this.skinColor = null;

		this.shirt = null;
		this.shirtColor = null;

		this.hair = null;
		this.hairColor = null;

		this.gltf = null;
		this.mixer = null;

		// animations
		this.tweenGroup = new TWEEN.Group();
		this.eyeBlink = true;
		this.talking = null;
		this.audioState = null;
		this.videoStream = null;
		this.transparent = this.personData.transparent || null;

		this.talkingRing = null;
		this.talkingRing2 = null;

		// helpers
		this.statusMethod = statusMethod || null;
		this.label = null
	}

	init(cb) {
		// todo: move this to its own component
		// console.log("init avatar", this.personData)
		// set and load bodytype
		// const bodyType = this.personData.avatar && this.personData.avatar.bodyType === 1 ? female_idle : male_idle;

		const bodyPromise = new Promise((resolve, reject) => {
			this._loadBody(this.bodyType, (data) => {
				this.bodyGroup.add(data)
				cb(this.bodyGroup)
				resolve()
			})
		})

		if (this.personData.avatar) {

			const modelAvatarPromise = new Promise((resolve, reject) => {
				AvatarSDK.getAvatar(this.personData.avatar.id, this.personData.avatar.userID, (meshData) => {
					// console.log("got baseMesh", meshData, this.defaultHead);
					this.statusMethod("got yo face", "scaleOut delay");

					if (!meshData) return reject();
					this.avatarHead = meshData;
					this.neck.add(this.avatarHead);

					// if glasses set the position
					if (this.glasses && this.modelInfo && this.modelInfo.facial_landmarks_68) {
						// test landmarks
						// console.log("scale", this.avatarHead.userData.scaleFactor)
						let slicedResult = null
						let vectorHelper = null

						let pairedCoords = this.modelInfo.facial_landmarks_68.reduce((result, value, index, array) => {
							if (index % 3 === 0) {
								slicedResult = array.slice(index, index + 3)
								vectorHelper = new THREE.Vector3(...slicedResult);
								vectorHelper.multiplyScalar(this.avatarHead.userData.scaleFactor).add(new THREE.Vector3(0, 14, 5))
								// console.log("sliced", slicedResult, vectorHelper)
								result.push(vectorHelper);
							}
							return result;
						}, []);

						// ADD CUBE for help
						// const helperGroup = new THREE.Group();
						// const boxGeometry = new THREE.BoxBufferGeometry(0.5, 0.5, 0.5);
						// let boxMaterial = new THREE.MeshBasicMaterial({ color: '#ff4444' });

						// console.log(pairedCoords)
						// pairedCoords.map((val, i) => {

						// 	if (i === 27) {
						// 		boxMaterial = new THREE.MeshBasicMaterial({ color: '#44ff44' });
						// 		// cubeHelper.material.needsUpdate = true
						// 	} else {
						// 		boxMaterial = new THREE.MeshBasicMaterial({ color: '#ff4444' });
						// 	}

						// 	const cubeHelper = new THREE.Mesh(boxGeometry, boxMaterial);
						// 	cubeHelper.position.set(val.x, val.y, val.z)
						// 	return helperGroup.add(cubeHelper)
						// })
						// this.neck.add(helperGroup);

						this.neck.attach(this.glasses)
						this.glasses.rotation.set(0,0,0)

						this.glasses.scale.set(this.avatarHead.userData.scaleFactor/115, 1, 1)
						this.glasses.position.set(pairedCoords[27].x, pairedCoords[0].y - 0.5, pairedCoords[27].z)
					};

					this._hideObject(this.defaultHead);

					this.animateEyeBlink()
					resolve()
				})
			})

			const modelInfoPromise = new Promise((resolve, reject) => {
				AvatarSDK.getAvatarModelInfo(this.personData.avatar.id, this.personData.avatar.userID, (modelInfo) => {
					// console.log("avatar model info", this.skin, modelInfo);
					// console.log("got model info", this.personData.avatar.id, this.personData.avatar.userID, modelInfo)
					if (!modelInfo) return reject()
					this.modelInfo = modelInfo;

					this.skinColor = `rgb(${modelInfo.skin_color.red}, ${modelInfo.skin_color.green}, ${modelInfo.skin_color.blue})`;
					this.hairColor = `rgb(${modelInfo.hair_color.red}, ${modelInfo.hair_color.green}, ${modelInfo.hair_color.blue})`;
					this._updateSkin()

					// console.log("skin color", this.skinColor)
					// load and add glasses
					if (modelInfo.remove_glasses) {
						this._loadGlasses(glasses, (glassesMesh) => {
							this.glasses = glassesMesh;

							this.glassesPosition = {
								x: 0,
								y: (modelInfo.facial_landmarks_68[1] * 1000) - 1,
								z: (-modelInfo.facial_landmarks_68[2] * 1000) + 7
							};

							// console.log(glassesPosition, modelInfo)
							if (this.avatarHead) {
								this.glasses.position.set(this.glassesPosition.x, this.glassesPosition.y, this.glassesPosition.z);
							} else {
								this.glasses.position.set(0, 9.5, 12.5);
							}

							this.head.add(this.glasses);
						});
					}

					resolve()
				})
			})

			Promise.all([bodyPromise, modelAvatarPromise, modelInfoPromise]).then(() => {
				// update skin now that avatarSDK head is back
				this._updateSkin()
			}).catch((error) => {
				// this._updateSkin()
			})

			// create this label on init, keep in mind if someone pops in and out
			// we don't hit this again
			this.createLabel()

		} else {
			// status method is quick way to send callback faces are loaded
			console.log("no avatarcode or playeruid")
			this.statusMethod("got yo face", "scaleOut delay");
		}

		// watch user movements (used for teleport.ist days)
		// if (this.personData.uid !== API.getCurrentUUID()) {
		// 	API.watchAvatarMovement((this.personData.uid), (data) => {
		// 		console.log("data", data)
		// 		this.animateFace(data);
		// 	});
		// } else {
		// 	console.log('skipping cause its me')
		// }

	}


	createLabel = (audioState, videoStream) => {
		// console.log("label render")
		// label see: https://threejsfundamentals.org/threejs/lessons/threejs-align-html-elements-to-3d.html
		const avatarLabels = document.querySelector('#avatarLabels');

		if (!avatarLabels) return

		// set audiostate, even if its null
		this.audioState = audioState
		this.videoStream = videoStream
		this.removeLabel()

		this.label = document.createElement('div');
		this.label.id = `label_${this.personData.phoneNumber || this.personData.uid}`;
		this.label.className = 'avatarLabelContainer';

		avatarLabels.appendChild(this.label);

		// console.log("label", videoStream)
		let markerElementProps = {
			id: this.personData.phoneNumber || this.personData.uid, //guests have uid, users have phoneNumber
			name: this.personData.firstName,
			audioState: audioState,
			videoStream: videoStream,
		};

		ReactDOM.render(
			React.createElement(AvatarLabel, markerElementProps),
			this.label
		);

		// add this for facemesh
		// this.faceMesh()
	}

	removeLabel = () => {
		const avatarLabels = document.querySelector('#avatarLabels');
		if (!avatarLabels) return

		if (this.label && avatarLabels.contains(this.label)) {
			// console.log("remove label")
			avatarLabels.removeChild(this.label);
		}
	}

	// super wip
	faceMesh = () => {
		if (this.face) {
			const video = document.querySelector(`#video_\\${this.personData.phoneNumber || this.personData.uid} video`)
			// console.log("face", video)
			if (video) {
				const videoTexture = new THREE.VideoTexture(video);
				// videoTexture.rotation = Math.PI
				// help: https://threejsfundamentals.org/threejs/lessons/threejs-textures.html
				videoTexture.flipY = false
				videoTexture.wrapS = THREE.RepeatWrapping
				videoTexture.wrapT = THREE.RepeatWrapping
				videoTexture.repeat.set(.4, .65)
				videoTexture.offset.set(.3, .1) //0 none, 1 full texture

				this.face.material.map = videoTexture
				this.face.material.color = new THREE.Color(0xFFFFFF)
				this.face.material.needsUpdate = true
			}

			if (this.avatarHead) {
				if (video) {
					const videoTexture = new THREE.VideoTexture(video);
					videoTexture.rotation = Math.PI/4
					// help: https://threejsfundamentals.org/threejs/lessons/threejs-textures.html
					// videoTexture.flipY = false
					videoTexture.wrapS = THREE.RepeatWrapping
					videoTexture.wrapT = THREE.RepeatWrapping
					videoTexture.repeat.set(2, 1)
					videoTexture.offset.set(0, .5) //0 none, 1 full texture

					this.avatarHead.material.map = videoTexture
					this.avatarHead.material.color = new THREE.Color(0xFFFFFF)
					this.avatarHead.material.needsUpdate = true
				}
			}
		}

	}

	_loadGlasses = (url, cb) => {
		loader.load(url, (gltf) => {
			this.glasses = gltf.scene;
			// this.scene.add( this.body );
			cb(gltf.scene);
		},
			// called while loading is progressing
			(xhr) => {
				// console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
				if (xhr.loaded === xhr.total) {
					console.log('done loading gltf')
				}
			},
			// called when loading has errors
			(error) => {
				console.log( 'An error happened', error );
			}
		);
	}

	_loadBody = (url, cb) => {
		// Load a glTF resource
		loader.load(url, (gltf) => {
			const randomHaircut = Math.random() < 0.5

			gltf.scene.traverse( ( child ) => {
				// console.log("child name", child.name, child)
				// get bones
				if (child.name === "Hips") {
					this.hips = child;
					this.hips.initialPosition = new THREE.Vector3().copy(this.hips.position);
				} else if (child.name === "Spine") {
					// console.log("spine found")
					this.spine = child
				} else if (child.name === "Neck") {
					// console.log("neck found")
					this.neck = child
				} else if (child.name === "Head") {
					this.head = child
				} else if (child.name === "head") {
					this.defaultHead = child
				}

				if ( child.isMesh ) {
					// console.log("child name", child.name)
					child.castShadow = true;
					child.receiveShadow = true;

					child.material.metalness = 0;



					if (child.name.includes("shirt")) {
						this.shirt = child;
					} else if (child.name.includes("hair-main")) {
						child.material.color = new THREE.Color(0x000000).convertGammaToLinear()
						child.material.needsUpdate = true
						child.visible = randomHaircut

						if (randomHaircut) {
							this.hair = child;
						}
					} else if (child.name.includes("hair-alt")) {
						child.material.color = new THREE.Color(0x000000).convertGammaToLinear()
						child.material.needsUpdate = true
						child.visible = !randomHaircut

						if (!randomHaircut) {
							this.hair = child;
						}
					} else if (child.name.includes("face")) {
						// console.log('got face')
						child.material.color = new THREE.Color(0xfdbba1).convertGammaToLinear()
						// child.material.color = child.material.color.multiplyScalar(1.25)
						child.material.needsUpdate = true
						this.face = child
					} else if (child.name.includes("eyeL") || child.name.includes("eyeR")) {
						const newEyeMaterial = child.material.clone()
						// console.log("child", child.material)
						child.material = newEyeMaterial
						child.material.color = new THREE.Color(0xFFFFFF).convertGammaToLinear()
						child.material.needsUpdate = true
						// child.visible = Math.random() < 0.5
					} else if (child.name.includes("headset")) {
						const newHeadsetMaterial = child.material.clone()
						// console.log("child", child.material)
						child.material = newHeadsetMaterial
						child.material.color = new THREE.Color(0x000000).convertGammaToLinear()
						child.material.needsUpdate = true
						// child.visible = Math.random() < 0.5
						child.visible = false
					} else if (child.name.includes("mouth")) {
						const newMouthMaterial = child.material.clone()
						// console.log("child", child.material)
						child.material = newMouthMaterial
						child.material.color = new THREE.Color(0x000000).convertGammaToLinear()
						child.material.needsUpdate = true
					} else if (child.name.includes("body")) {
						// child.material.color = child.material.color.multiplyScalar(1.25)
						child.material.color = new THREE.Color(0xfdbba1).convertGammaToLinear()
						child.material.needsUpdate = true
						this.skin = child;
					} else if (child.name.includes("headRing")) {
						child.visible = false
						child.geometry.dispose()
					}
				}
			})

			// preset shirt and skin color
			API.getResource("colors", (data) => {
				this.shirtColor = new THREE.Color( parseInt(Utils.randomChoice(data.shirtColors))).convertGammaToLinear()
				// update shirt and skin color even thoguh avatarsdk isn't back yet
				this.shirt.material.color = this.shirtColor
				this.shirt.material.needsUpdate = true

				if (!this.skinColor) {
					this.skinColor = new THREE.Color( parseInt(Utils.randomChoice(data.skinColors))).convertGammaToLinear()
					this.skin.material.color = this.skinColor
					this.skin.material.needsUpdate = true
					this.face.material.color = this.skinColor
					this.face.material.needsUpdate = true
				}

				this._updateSkin()
			})
			// box helper for shader
			// const boxGeometry = new THREE.BoxBufferGeometry(60, 30, 30);
			// const boxMaterial =  new FresnelShader({
			// 	color1: new THREE.Color(0xff4444),
			// 	color2: new THREE.Color(0x4444ff),
			// 	scale: 1.4,
			// 	power: 1
			// })
			// const cubeHelper = new THREE.Mesh(boxGeometry, boxMaterial);
			// cubeHelper.position.set(0, 120, 0)
			// // 	return helperGroup.add(cubeHelper)
			// gltf.scene.add(cubeHelper)

			// attach neck for generic
			if (this.neck && this.defaultHead) {
				this.neck.attach(this.defaultHead)
			}

			this.gltf = gltf;

			if (navigator.userAgent !== "snap") {
				this.playAnimations(this.gltf);
			}

			gltf.scene.name = url;
			this.body = gltf.scene;

			cb(this.body);
		},
			// called while loading is progressing
			(xhr) => {
				// console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
				if (xhr.loaded === xhr.total) {
					console.log('done loading gltf')
				}
			},
			// called when loading has errors
			(error) => {
				console.log( 'An error happened', error );
			}
		);
	}

	playAnimations() {
		// console.log("playanimations", this.gltf)
		// return
		if (this.gltf) {
			this.mixer = new THREE.AnimationMixer(this.gltf.scene);

			// filter out hips
			const valuesToRemove = ['Hips.rotation', 'Hips.position', 'Hips.quaternion', 'Neck.position', 'Neck.rotation', 'Neck.quaternion', 'Spine.quaternion']
			const mouthValue = ['mouth']

			const filteredAnimations = this.gltf.animations[0].tracks.filter(item => !valuesToRemove.includes(item.name))
			// const mouthAnimation = this.gltf.animations[0].tracks.filter(item => ['mouth'].includes(item.name))
			// console.log("mouth", filteredAnimations)

			// make sure when exporting animations pla is checked on
			// console.log("tracks", this.gltf.animations)
			this.gltf.animations[0].tracks = filteredAnimations;
			// console.log("animations", filteredAnimations, this.gltf.animations[0].tracks)
			const randomStart = Utils.randomNumber(-1, 1)

			this.gltf.animations.forEach((clip) => {
				this.mixer.clipAction(clip).startAt(randomStart).play();
			});
		}
	}

	stopTalking() {
		this.talking = false
	}

	animateEyeBlink() {
		let have_morph_targets = !!this.avatarHead && this.avatarHead.morphTargetInfluences && this.avatarHead.morphTargetInfluences.length > 0;

		if (have_morph_targets && this.eyeBlink) {
			this._tweenRepeatBlendshape(this.avatarHead.morphTargetInfluences, "eyeBlinkLeft", 1, 200);
			this._tweenRepeatBlendshape(this.avatarHead.morphTargetInfluences, "eyeBlinkRight", 1, 200);
			// console.log("is blinking?", this.eyeBlink)
		}
	}

	animateTalking(isTalking) {
		// default eye blink
		let have_morph_targets = !!this.avatarHead && this.avatarHead.morphTargetInfluences && this.avatarHead.morphTargetInfluences.length > 0;
		// console.log("eyeblink", have_morph_targets)
		// if (this.refs && this.refs.label) {
		// 	console.log(this.refs.label)
		// 	// this.refs.label.setMuteState("talking")
		// }

		if (have_morph_targets) {
			// todo: this is bad since we're just running this animation at all times
			// we should find a way to see if one of these is still active by setting null
			// when it's done chaining

			if (isTalking) {
				this._tweenRepeatBlendshape(this.avatarHead.morphTargetInfluences, "jawOpen", 1, 100, 0, true);
			} else {
				// console.log("is false", this.talking)
			}
		}

		// set talking ring
		if (this.talkingRing) {
			// console.log('talking ring', this.talkingRing.position.y, this.talkingRing.geometry.parameters.radius)

			if (isTalking) {
				const newRingScaleUp = new THREE.Vector3(1, 1, 1)
				const newRingScaleDown = new THREE.Vector3(0, 0, 0)

				const duration = 1600

				const scaleUp = new TWEEN.Tween(this.talkingRing.scale)
					.to( newRingScaleUp, duration)
					.easing(TWEEN.Easing.Exponential.Out)
					.onComplete(() => {
						const scaleDown = new TWEEN.Tween(this.talkingRing.scale)
							.to( newRingScaleDown, 100)
							.easing(TWEEN.Easing.Exponential.Out)
							.onComplete(() => {
								this.talkingRing.material.opacity = 1
								this.talkingRing.material.needsUpdate = true
							})
							.start()
					})
					.start();

				new TWEEN.Tween(this.talkingRing.material)
					.to({opacity: 0}, duration/2)
					.delay(duration/2)
					// .easing(TWEEN.Easing.Exponential.Out)
					.start();

				const scaleUp2 = new TWEEN.Tween(this.talkingRing2.scale)
					.to( newRingScaleUp, duration)
					.easing(TWEEN.Easing.Exponential.Out)
					.delay(duration/2)
					.onComplete(() => {
						const scaleDown = new TWEEN.Tween(this.talkingRing2.scale)
							.to( newRingScaleDown, 100)
							.easing(TWEEN.Easing.Exponential.Out)
							.onComplete(() => {
								this.talkingRing2.material.opacity = 1
								this.talkingRing2.material.needsUpdate = true
							})
							.start()
					})
					.start();

				new TWEEN.Tween(this.talkingRing2.material)
					.to({opacity: 0}, duration/2)
					.delay(duration/2 + duration/2)
					// .easing(TWEEN.Easing.Exponential.Out)
					.start();
			}

		} else {
			const talkingRingGeometry = new THREE.TorusGeometry(80, 1, 8, 24);
			const ringColor = new THREE.Color(0x2288FF).convertGammaToLinear()
			const talkingRingMaterial = new THREE.MeshBasicMaterial( {color: ringColor, transparent: true, refractionRatio: 0, reflectivity: 0} );
			this.talkingRing = new THREE.Mesh( talkingRingGeometry, talkingRingMaterial );
			this.talkingRing.position.y = 10
			this.talkingRing.rotation.x = Math.PI/2
			this.talkingRing.scale.set(0, 0, 0)

			this.bodyGroup.add(this.talkingRing);

			this.talkingRing2 = this.talkingRing.clone()
			this.talkingRing2.material = this.talkingRing.material.clone()
			this.talkingRing2.material.needsUpdate = true
			this.bodyGroup.add(this.talkingRing2);
		}

		//  else if () {
		// 	if (isTalking) {

		// 	}
		// }
	}

	animateFace(data) {

		if (this.body && data) {
			// console.log("animate", data.rotation)
			// console.log('animating face', data, this.body)
			// only render this group
			this.tweenGroup.update();

			const positionFactor = .2;

			// console.log(this.hips.initialPosition)
			// poor man's ik
			let body = {
				position: {
					x: data.global.position.x,
					y: data.global.position.y,
					z: data.global.position.z
				}, rotation: {
					x: data.global.rotation.x,
					y: data.global.rotation.y,
					z: data.global.rotation.z
				}
			};
			// console.log('animating body', data.global.position)

			let hips = {
				position: {
					x: (data.position.x * positionFactor),
					y: this.hips.initialPosition.y + (data.position.y * positionFactor),
					z: this.hips.initialPosition.z + data.position.z
				}, rotation: {
					x: 0,
					y: data.rotation.y/4,
					z: 0
				}
			};

			let spine = {
				rotation: {
					x: data.rotation.x/3,
					y: data.rotation.y/2,
					z: data.rotation.z/2
				}
			}

			let neck = {
				rotation: {
					x: data.rotation.x - Math.PI/8,
					y: data.rotation.y,
					z: data.rotation.z
				}
			}

			// this.neck.rotation.set(new THREE.Vector3(...data.rotation))
			// this.avatarHead.rotation.x = Math.PI/2
			// this.avatarHead.rotation.y = Math.PI/2
			// this.avatarHead.rotation.z = Math.PI/2
			// return

			// remove tweens over a certain amount
			const allGroupTweens = this.tweenGroup.getAll().length;

			// console.log("all tweens", allGroupTweens);
			if (allGroupTweens > 8) {
				this.tweenGroup.removeAll();
				// console.log("remove tweens", allGroupTweens);
			}

			// don't spin
			// const rotationYDelta = Math.abs(this.body.rotation.y - body.rotation.y);

			// if (rotationYDelta > Math.PI) {
			// 	let remappedRotationY = this.body.rotation.y > 0 ? this.body.rotation.y - (2*Math.PI) : (2*Math.PI) + this.body.rotation.y;
			// 	console.log('rotate', this.body.rotation.y, body.rotation.y, remappedRotationY)
			// 	this.body.rotation.set(this.body.rotation.x, remappedRotationY, this.body.rotation.z)
			// }

			// tween coordinates
			// this._tweenPoint(this.body.position, body.position, 2000);
			// this._tweenPoint(this.body.rotation, body.rotation, 2000);


			// this._tweenPoint(this.hips.position, hips.position);
			// this._tweenPoint(this.hips.rotation, hips.rotation);

			this._tweenPoint(this.spine.rotation, spine.rotation);
			this._tweenPoint(this.neck.rotation, neck.rotation);

			// mouth detection
			let have_morph_targets = !!this.avatarHead && this.avatarHead.morphTargetInfluences && this.avatarHead.morphTargetInfluences.length > 0;

			if (have_morph_targets) {
				// this.avatarHead.morphTargetInfluences[1] = Math.max(data.expressions.sad, data.expressions.disgusted);

				// this.avatarHead.morphTargetInfluences[0] = data.blendshapes.jawOpen;

				// console.log(this.avatarHead.morphTargetInfluences[0]); // this is just a value
				// this.avatarHead.morphTargetInfluences[2] = Math.max(data.expressions.sad, data.expressions.disgusted);
				const eyeFactor = 2;
				let eyes;
				// console.log("mmouse",data.mousePosition)
				if (data.mousePosition) {
					eyes = {
						eyeLookUpLeft: Math.min(data.mousePosition.y, 0) * -1, // up
						eyeLookOutLeft: Math.max(data.mousePosition.x, 0) * 1, // out
						eyeLookDownLeft: Math.max(data.mousePosition.y, 0) * 1, // down
						eyeLookInLeft: Math.min(data.mousePosition.x, 0) * -1, // in
						eyeLookUpRight: Math.min(data.mousePosition.y, 0) * -1, // up
						eyeLookOutRight: Math.min(data.mousePosition.x, 0) * -1, // out
						eyeLookDownRight: Math.max(data.mousePosition.y, 0) * 1, // down
						eyeLookInRight: Math.max(data.mousePosition.x, 0) * 1 // in
					}
				} else {
					eyes = {
						eyeLookUpLeft: Math.min(data.rotation.x, 0) * -eyeFactor, // up
						eyeLookOutLeft: Math.max(data.rotation.y, 0) * eyeFactor, // out
						eyeLookDownLeft: Math.max(data.rotation.x, 0) * eyeFactor, // down
						eyeLookInLeft: Math.min(data.rotation.y, 0) * -eyeFactor, // in
						eyeLookUpRight: Math.min(data.rotation.x, 0) * -eyeFactor, // up
						eyeLookOutRight: Math.min(data.rotation.y, 0) * -eyeFactor, // out
						eyeLookDownRight: Math.max(data.rotation.x, 0) * eyeFactor, // down
						eyeLookInRight: Math.max(data.rotation.y, 0) * eyeFactor // in
					}
				}

				this._tweenBlendshape(this.avatarHead.morphTargetInfluences, eyes, tweenDuration);

				// console.log(data.blendshapes.jawOpen, data.expressions.surprised)
				let faceMorphs = {
					jawOpen: data.blendshapes.jawOpen,
					mouthSmileLeft: data.expressions.happy,
					mouthSmileRight: data.expressions.happy,
					mouthFrownLeft: data.expressions.sad,
					mouthFrownRight: data.expressions.sad,
					mouthLeft: data.blendshapes.mouthLeft,
					mouthRight: data.blendshapes.mouthRight,

					// mouthPucker: data.blendshapes.mouthPucker,
					browInnerUp: Math.max(data.expressions.suprised, data.expressions.happy)
				}

				this._tweenBlendshape(this.avatarHead.morphTargetInfluences, faceMorphs, tweenDuration);

				if (!this.eyeBlink) {
					this._tweenRepeatBlendshape(this.avatarHead.morphTargetInfluences, "eyeBlinkLeft", 1, 200);
					this._tweenRepeatBlendshape(this.avatarHead.morphTargetInfluences, "eyeBlinkRight", 1, 200);
				}
			}

		} else {
			console.log('play default animation')
			this.playAnimations(this.gltf);
		}


	}

	getNeckPosition = () => {
		// console.log(this.neck.rotation)
		return this.neck.rotation
	}

	_tweenPoint = (target, endParams, duration=tweenDuration) => {
		// todo: garbage collect tweens

		new TWEEN.Tween( target, this.tweenGroup )
			.to( endParams, duration )
			.easing( TWEEN.Easing.Exponential.Out )
			.start();
	}

	_tweenBlendshape = (target, variables, endParams, duration=tweenDuration) => {
		Object.keys(variables).map( (key) => {
			// let blendshapeKey = AvatarSDK.blendshapes()[value];
			// console.log("hello", key, AvatarSDK.blendshapes()[key], variables[key]);
			return this._tweenVariable(target, AvatarSDK.blendshapes()[key], variables[key], duration);
		})
	}

	_tweenVariable = (target, variable, endParams, duration=tweenDuration) => {
		// todo: garbage collect tweens
		let tweenTo = {};
		tweenTo[variable] = endParams; // set the custom variable to the target

		return new TWEEN.Tween( target, this.tweenGroup )
			.to( tweenTo, duration )
			.easing( TWEEN.Easing.Exponential.Out )
			.start();
	}

	_tweenRepeatBlendshape = (target, variable, endParams, duration=tweenDuration, delay=tweenDelay, randomize=false) => {
		const mappedBlendshapeVariable = AvatarSDK.blendshapes()[variable];

		// todo: garbage collect tweens
		let tweenTo = {};
		let tweenBack = {};
		let tweenLoop = {};
		let tweenLoopDuration = 0;
		let tweenLoopDelay = 0;

		tweenTo[mappedBlendshapeVariable] = endParams; // set the custom variable to the target
		tweenBack[mappedBlendshapeVariable] = 0; // set the custom variable to the target

		// this._tweenRepeatBlendshape(this.avatarHead.morphTargetInfluences, "jawOpen", Utils.randomNumber(0,1), Utils.randomNumber(100,200), 0);

		const randomizeTween = () => {

			const randomizedTweenOpen = new TWEEN.Tween( target )
				.to( tweenLoop, tweenLoopDuration )
				.delay(tweenLoopDelay)
				.easing( TWEEN.Easing.Exponential.Out )

			const randomizedTweenClose = new TWEEN.Tween( target )
				.to( tweenBack, tweenLoopDuration )
				.delay(tweenLoopDelay)
				.easing( TWEEN.Easing.Exponential.Out )

			const tweenEnd = new TWEEN.Tween( target )
				.to( tweenBack, 100 )
				.easing( TWEEN.Easing.Exponential.Out )

			randomizedTweenClose.onComplete(() => {
				if (variable === "jawOpen" && this.talking) {
					randomizedTweenClose.chain(randomizedTweenOpen)
					// console.log('chain open jaw', this.talking)
				} else {
					// console.log('no loop anymore', this.talking)
				}
			})

			randomizedTweenOpen.onComplete(() => {
				if (variable === "jawOpen" && this.talking) {
					tweenLoop[mappedBlendshapeVariable] = Utils.randomNumber(0, endParams)
					tweenLoopDuration = Utils.randomNumber(duration/2, duration*2)
					tweenLoopDelay = Utils.randomNumber(delay/2, delay)
					randomizedTweenOpen.chain(randomizeTween())
					// console.log('chain startover')
				} else {
					// console.log('no mas')
					tweenEnd.start()
				}

			})
			return randomizedTweenClose
		}

		// don't add these to tween group, cause itll get cancelled
		const tweenA = new TWEEN.Tween( target )
			.to( tweenTo, duration )
			.delay(delay)
			.easing( TWEEN.Easing.Exponential.Out )

		const tweenB = new TWEEN.Tween( target )
			.to( tweenBack, duration*2 )
			// .delay(0)
			.easing( TWEEN.Easing.Exponential.Out )

		if (randomize) {
			tweenA.chain(randomizeTween())
		} else {
			tweenA.chain(tweenB);
			tweenB.chain(tweenA);
		}

		tweenA.start()

		return tweenA
	}

	updateAnimations(delta) {
		// console.log("update anim")
		// only render this group
		if (this.tweenGroup) this.tweenGroup.update();
		if (this.mixer) this.mixer.update( delta );
	}

	_pulseObject = (object) => {
		// play morphs
		// if (this.defaultHead) {
		// 	this._pulseObject(this.defaultHead)
		// }
		object.traverse( child => {
			if (child.materials) {
				console.log(child, child.materials)
				// child.materials[0].transparent = true;
				// child.materials[0].opacity = 1 + Math.sin(new Date().getTime() * .0025);//or any other value you like
				// child.visible = false;
			}
			return
		});
	}

	_updateSkin = (transparent=false) => {
		// helpful material guide https://threejsfundamentals.org/threejs/lessons/threejs-materials.html
		// console.log("update skin", this.personData.firstName, this.body)
		// console.log("update skin", this.skin, this.shirt)

		// if (this.defaultHead && this.defaultHead.material) {
		// 	const defaultHeadMaterial = this.defaultHead.material.copy()
		// 	this.defaultHead.material.map = null;
		// 	this.defaultHead.material.color.set( this.skinColor );
		// 	this.defaultHead.material.needsUpdate = true;
		// }
		let backgroundColor = new THREE.Color(0xffffff)

		if (this.body && this.body.parent && this.body.parent.background) {
			backgroundColor = new THREE.Color().copy(this.body.parent.background)
		}

		if (!transparent) {
			this.transparent = false
			const avatarMaterialNames = {
				hair: {
					mesh: this.hair
				},
				skin: {
					mesh: this.skin
				},
				face: {
					mesh: this.face
				},
				shirt: {
					mesh: this.shirt
				},
				head: {
					mesh: this.avatarHead ? this.avatarHead : null
				}
			}

			// Object.keys(avatarMaterialNames).forEach((key) => {
			// 	if (avatarMaterialNames[key].mesh) {
			// 		console.log("loop mesh and material", avatarMaterialNames[key].mesh.name)
			// 	}
			// })

			// if (this.avatarHead) {
			// 	this.avatarHead.traverse((child) => {
			// 		if (child.isMesh) {
			// 			console.log("body traverse", child)
			// 		}
			// 	})
			// }

			// this.body.traverse((child) => {
			// 	if (child.isMesh) {
			// 		console.log("body traverse", child.name)
			// 	}
			// })

			if (this.hair && this.hair.material) {
				// helpful material guide https://threejsfundamentals.org/threejs/lessons/threejs-materials.html
				// this.hair.material.map = null;
				this.hair.material.color.set(this.hairColor).convertGammaToLinear()
				this.hair.material.needsUpdate = true;
			}

			if (this.skin && this.skin.material) {
				// todo: if i wanna use fresnel for face and skin
				// if (!this.shader) {
				// 	this.face.material = new FresnelShader({
				// 		color1: new THREE.Color(this.skinColor).multiplyScalar(1.2),
				// 		color2: new THREE.Color(this.skinColor),
				// 		scale: 1,
				// 		map: this.face.material.map,
				// 		power: 1
				// 	})
				// 	this.skin.material = new FresnelShader({
				// 		color1: new THREE.Color(this.skinColor).multiplyScalar(1.2),
				// 		color2: new THREE.Color(this.skinColor),
				// 		scale: 1,
				// 		map: this.skin.material.map,
				// 		power: 1
				// 	})
				// }

				// standard mesh for skin, anecdotally looks better and less fake than fresnel
				this.face.material = new THREE.MeshStandardMaterial({
					// skinning: true,
					roughness: .75,
					// reflectivity: .5,
					metalness: 0,
					map: this.face.material.map,
					// aoMapIntensity: 1,
					emissive: new THREE.Color(this.skinColor).convertGammaToLinear().multiplyScalar(.2),
					color: new THREE.Color(this.skinColor).convertGammaToLinear().multiplyScalar(1.4),
				})

				this.skin.material = new THREE.MeshStandardMaterial({
					// skinning: true,
					roughness: .75,
					// reflectivity: .5,
					metalness: 0,
					map: this.skin.material.map,
					emissive: new THREE.Color(this.skinColor).convertGammaToLinear().multiplyScalar(.2),
					color: new THREE.Color(this.skinColor).convertGammaToLinear().multiplyScalar(1.4),
				})
			}

			// set shirt
			if (this.shirt && this.shirt.material) {
				// console.log('updateskin shirt', this.personData.firstName, this.shirt, this.shirtColor, this.shader, this.body)
				if (this.shader) {
					this.shirt.material = new FresnelShader({
						color1: new THREE.Color(this.shirtColor).multiplyScalar(1.25).lerp(backgroundColor, 0.7),
						color2: this.shirtColor,
						scale: 1,
						map: this.shirt.material.map
						// power: 10
					})
				} else {
					this.shirt.material = new THREE.MeshPhysicalMaterial({
						skinning: true,
						roughness: .5,
						// reflectivity: .5,
						metalness: 0,
						emissive: new THREE.Color(this.shirtColor).lerp(backgroundColor, 0.5),
						color: this.shirtColor
					})
				}
			}
		} else {
			// set transparent
			console.log("updating to transparent skin", this.personData.firstName)
			this.transparent = true

			const transMaterial = new THREE.MeshBasicMaterial({
				skinning: true,
				side: THREE.FrontSide,
				// transparent: false,
				// opacity: 1,
				// reflectivity: .5,
				// roughness: 1,
				// metalness: 0,
				// emissive: new THREE.Color(this.shirtColor).lerp(backgroundColor, 0.5),
				color: new THREE.Color(this.shirtColor).multiplyScalar(1).lerp(backgroundColor, 0.5),
				// map: child.material.map
			})

			if (this.hair && this.hair.material, this.hair.material.color) {
				this.hair.material = transMaterial
				this.hair.material.needsUpdate = true;
			}

			if (this.skin && this.skin.material) {
				this.face.material = transMaterial
				this.skin.material = transMaterial

				this.face.material.needsUpdate = true;
				this.skin.material.needsUpdate = true;
			}

			if (this.shirt && this.shirt.material) {
				this.shirt.material = transMaterial

				this.shirt.material.needsUpdate = true;
			}

			if (this.avatarHead) {
				this.avatarHead.traverse((child) => {
					if (child.material) {
						child.material = transMaterial
						child.material.needsUpdate = true
					}
				})
			}
		}

	}

	_hideObject = (object) => {
		object.traverse( child => {
			// if (child instanceof THREE.Mesh) {
			if (child.isMesh) {
				// console.log("hide", child.geometry, child.material)
				child.visible = false;
				child.geometry.dispose()
				child.material.dispose()
			}
			return
		});
	}

	updateBody = (avatarValue) => {

		// let avatarBodyCycle = Utils.cycle(Utils.shuffle(avatarBodies))
		// console.log("updating avatar", this.body)

		// const bodyType = this.personData.avatar && this.personData.avatar.bodyType === 1 ? female_idle : male_idle;

		this._loadBody(avatarBodyCycle(), (data) => {
			// remove and replace body
			this._hideObject(this.bodyGroup)
			this.bodyGroup.add(data)
			// cb(data)
		})

		// const bodyPromise = new Promise((resolve, reject) => {
		// 	this._loadBody(bodyType, (data) => {
		// 		cb(data)
		// 		resolve()
		// 	})
		// })

	}

	update = (avatarValue) => {
		// console.log("updating avatar", Object.keys(avatarValue))
		Object.keys(avatarValue).map((key) => {
			if (this[key]) {
				const newColor = new THREE.Color(`rgb(${avatarValue[key][0]}, ${avatarValue[key][1]}, ${avatarValue[key][2]})`).convertGammaToLinear()

				if (key == "shirt") {
					// console.log("shirt", this.shirt.material)
					// this.shirt.material.color1.set(new THREE.Color(0xff4444));
					// this.shirt.material.color2.set(new THREE.Color(0xff4444));

					// this.shirt.material = new THREE.MeshPhysicalMaterial({
					// 	skinning: true,
					// 	roughness: .5,
					// 	// reflectivity: .5,
					// 	metalness: 0,
					// 	emissive: newColor,
					// 	color: newColor
					// })

					this.shirt.material.color1.set(newColor.clone().multiplyScalar(1.25).lerp(new THREE.Color(0xffffff), 0.7));
					this.shirt.material.color2.set(newColor.clone());
					this.shirt.material.needsUpdate = true;
				} else if (key == "skin") {
					// console.log("skin or body", this.skin, this.skin.material)

					this.skin.material.emissive = newColor.clone().multiplyScalar(.2);
					this.skin.material.color = newColor.clone().multiplyScalar(1.4);
					this.skin.material.needsUpdate = true;

					this.face.material.emissive = newColor.clone().multiplyScalar(.2);
					this.face.material.color = newColor.clone().multiplyScalar(1.4);
					this.face.material.needsUpdate = true;
				} else {
					this[key].material.color.set(newColor);
					this[key].material.needsUpdate = true;
				}
			}
		})
	}
}

export default SceneAvatar;
