import React from 'react';
import { withRouter } from "react-router-dom";

// API
import API from './components/API.js';
import Utils from './components/Utils.js';
import Values from "./components/Values.js";
import MetaTags from './components/MetaTags.js'
import AvatarCreator from './components/AvatarCreator.js';

// components
import Scene from './components/Scene.js';

// document.body.classList.add("circle");

class User extends React.Component {

	constructor(props)
	{
		super(props);

		this.state = {
			showDebug: Utils.isLocalhost(),
			autoRotate: !Utils.isUserAgent("snap"),

			filteredPeople: {},
			rearFacingCamera: false,
			showRoster: false,
			showBackground: true,
			showAdmin: false,
			showEditPanel: false,
			roster: {},
			sayCheese: false,
			selfie: null,
			allowMovement: null,
			status: {
				message: null,
				animation: null
			},
			meta: {}
		};
		// prerender.io flag
		window.prerenderReady = false;
	}

	componentDidMount() {
		// get roster
		let filteredPeople = {};
		const UID = this.props.match.params.id;

		const userPromise = new Promise((resolve, reject) => {
			if (this.props.match.path.includes("/invite")) {
				API.getUserInvite(UID, (userData) => {
					if (userData) {
						resolve(userData)
					} else {
						reject()
					}
				});
			} else if (this.props.match.params.name) {
				API.getUserByName(this.props.match.params.name, (userData) => {
					if (userData) {
						resolve(userData)
					} else {
						reject()
					}
				})
			} else if (!UID) {
				resolve("guest")
			} else {
				API.getUser(UID, (userData) => {
					if (userData) {
						resolve(userData)
					} else {
						reject()
					}
				});
			}
		})

		userPromise.then((userData) => {
			// console.log("got data", userData)

			if (userData === "guest") {
				setInterval(() => {
					const guestUID = API.generateUUID()
					const randomPeople = {}
					randomPeople[guestUID] = API.getGuest(guestUID);
					console.log("interval", guestUID, filteredPeople)

					this.setState({filteredPeople: randomPeople});
				}, 1000);
			}

			filteredPeople[UID] = userData;
			this.setState({filteredPeople});

			this.setState({
				meta: {
					title: `${Values.title}: ${userData.firstName} ${userData.lastName}`,
					image: userData.thumbnail && userData.thumbnail.imageURL ? userData.thumbnail.imageURL : null,
					type: "profile"
				}
			}, () => {
				window.prerenderReady = true;
			});
		}).catch(error => {
			this.props.history.push('/404')
		});

		// only show admin if admin and is invite page
		API.isAdmin().then(() => {
			this.setState({showAdmin: true})
		}).catch(() => {
			this.setState({showAdmin: false})
		})
		// not sure if i need this cause people not sure if it updates
		// this.refs.scene.goToSecondPerson(true);
	}

	componentWillUnmount() {
		console.log("home unmounting");
		this.refs.scene.stop();
	}

	render() {

		return (
			<div>
				<MetaTags data={this.state.meta} />

				{ this.state.sayCheese ?
					<div id='url2png-cheese' className="absolute z2 white h0"></div>
					:
					null
				}

				<div
					id="scene"
					className="full-viewport absolute"
				>
					<Scene
						ref="scene"
						showDebug={this.state.showDebug}
						bg={false}
						bgColor={"random"}
						match = {this.props.match}
						people={this.state.filteredPeople}
						parentStatusMethod={this._updateStatus}
						autoRotate={this.state.autoRotate}
					/>
				</div>

				<div className={`absolute right-0 hover white pt3 pr3`}>
					{
						this.state.showAdmin ?
								<button
									className={`hover white px2`}
									onClick={ () => {
										this.setState({ showEditPanel: !this.state.showEditPanel })
									}}
								>
									<i className={`material-icons scaleIn`}>edit</i>
								</button>
						: null
					}
					{
						Utils.isMobile() ?
							<button
								className={`hover white pl2`}
								onClick={ () => {
									this.refs.scene.toggleOrientation();
									this.setState({ rearFacingCamera: !this.state.rearFacingCamera })
								}}
							>
								<i className={`material-icons scaleIn`}>{this.state.rearFacingCamera ? "touch_app" : "360"}</i>
							</button>
						: null
					}
				</div>

				<div className={`flex flex-column mb-r exclusion absolute bottom-0 centerX`}>
					{ this.state.filteredPeople[this.props.match.params.id] && this.state.showAdmin ?
						<AvatarCreator
							show={this.state.showEditPanel}
							firstName={this.state.filteredPeople[this.props.match.params.id].firstName}
							lastName={this.state.filteredPeople[this.props.match.params.id].lastName}
							phoneNumber={this.state.filteredPeople[this.props.match.params.id].phoneNumber}
							playerUID={this.state.filteredPeople[this.props.match.params.id].avatar.userID}
							parentUserMethod={this._updateAvatar}
						/>
						// <div className="h2">{this.state.status.message}</div>
						: null
					}

					<button
						className={`pill-button z2 ${navigator.userAgent === "snap" ? "hide" : ""}`}
						onClick={(e) => {
							e.preventDefault();
							window.location.href=Values.appURL;
						}}
					>{`Get ${Values.title}`}
					</button>
				</div>
			</div>
		);
	}

	_updateStatus = (message, animation="fadePulse") => {
		// console.log("status update", message)

		if (message === "got yo face") {
			this.setState({sayCheese: true})
			console.log("say cheese fries")
		}

		this.setState({
			status: {
				message: message,
				animation: animation
			}
		});
	}

	_updateAvatar = (newPeople) => {
		console.log('update avatar', newPeople, this.state.filteredPeople)
		if (newPeople) {

			let filteredPeople = {
				...this.state.filteredPeople,
				...newPeople
			}

			this.setState({
				filteredPeople: filteredPeople
			});

			window.location.reload(false);
		}
	}
}

export default withRouter(User);
