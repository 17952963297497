// https://api.avatarsdk.com/samples/threejs/
// https://api.avatarsdk.com/#id25

// import { Database } from './Firebase';
import axios from 'axios';
import * as THREE from 'three';
import API from './API.js';

import AvatarSDKLoaders from './AvatarSDKLoaders'
import { Functions } from './Firebase';

const API_ROOT = 'https://api.avatarsdk.com';

let authToken = null;
let authType = null;

let mesh = null;
let texture = null;

let _progress = {};

class AvatarSDK {

	static authorize() {
		return new Promise((resolve, reject) => {

			if (API.getSessionStorage("authToken") && API.getSessionStorage("authToken") && API.getSessionStorage("authExpireTime") >= (Date.now() / 1000)) {
				// console.log("authing exists", API.getSessionStorage("authToken"))
				authToken = API.getSessionStorage("authToken");
				authType = API.getSessionStorage("authType")
				// console.log("not expired yet")
				resolve();
			} else {
				const authAvatarSDK = Functions.httpsCallable('avatarSDK-auth');
				authAvatarSDK().then((auth) => {
			        let authForm = new FormData();
			        authForm.append('grant_type', 'client_credentials');

			        axios({
			          method: "POST",
			          headers: {
			            Authorization: `Basic ${auth.data}`
			          },
			          url: "https://api.avatarsdk.com/o/token/",
			          data: authForm
			        }).then( (res) => {
			          const timeAuthExpiresSeconds =  (Date.now() / 1000) + res.data.expires_in;
			          // console.log("avatar sdk initiated until ", timeAuthExpiresSeconds);

			          API.setSessionStorage("authToken", res.data.access_token)
			          API.setSessionStorage("authType", res.data.token_type)
			          API.setSessionStorage("authExpireTime", timeAuthExpiresSeconds)

			          authToken = res.data.access_token;
			          authType = res.data.token_type;
			          resolve();
			        }, (error) => {
			          console.log(error);
			          reject(error);
			        });

				}).catch((error) => {
					reject(error);
				});
			}
		});
	}

	static _getAvatarSDKPlayerUID(user, cb) {
		// check for player id if not create one
		console.log("user player", user.playerUID)
		if (user.playerUID) {
			console.log("existing player", user.playerUID)
			return cb(user.playerUID);
		} else {
			const fd = new FormData();
			fd.append("comment", user.uid);

			axios
				.request({
					method: "POST",
					url: "https://api.avatarsdk.com/players/",
					headers: {
						'Authorization': `${authType} ${authToken}`,
						'Content-Type': "multipart/form-data"
					},
					data: fd
				})
				.then(response => {
					user.playerUID = response.data.code;
					console.log("new player", user.playerUID)
					// API.setCurrentUserPlayerUID(user.playerUID);

					return cb(user.playerUID);
				})
				.catch(error => {
					console.warn("Failed to create new AvatarSDK user: ", error);
					return cb(null);
				});
		}

	}

	static uploadAvatar(user, photoURL, cb) {
		let authPromise = this.authorize();

		authPromise.then(() => {

			this._getAvatarSDKPlayerUID( user, (playerUID) => {

				const fd = new FormData();
				fd.append("name", `${user.firstName} ${user.lastName}`);
				fd.append("pipeline", "head_2.0");
				fd.append("pipeline_subtype", "head/mobile");
					fd.append(
					"resources",
					JSON.stringify({
						model_info: {
							plus: [
								'age',
								'gender',
								'facial_landmarks_68',
								'race',
								'hair_color',
								'skin_color',
								'eye_sclera_color',
								'eye_iris_color'
							]
						},
						// blendshapes: {
						// 	'plus': [
						// 		'mobile_51'
						// 	],
						// },
						avatar_modifications: {
							plus: {
								repack_texture: true,
								// allow_modify_neck: true,
								remove_smile: true,
								remove_glasses: true,
								enhance_lighting: true,
								// slightly_cartoonish_texture: true,
								// generated_haircut_faces_count: 512,
								generated_haircut_texture_size: 1024
								// hair_color: {
								// 	"red": 0, "green": 255, "blue": 0
								// },
								// eye_iris_color: {
								// 	"red": 0, "green": 255, "blue": 0
								// }
							}
						}
					})
				);

				fd.append("photo", photoURL);
				// console.log('fd', fd, playerUID)

				axios
					.request({
						method: "POST",
								url: "https://api.avatarsdk.com/avatars/",
						headers: {
							'Authorization': `${authType} ${authToken}`,
							'Content-Type': "multipart/form-data",
							'X-PlayerUID': playerUID
						},
							data: fd
						})
					.then( res => {
						// console.log('post works')
						this._handleGeneratingProcess(res.data.url, playerUID, cb);
					})
					.catch(error => console.error(error));
			})


		});
	}


	static _handleGeneratingProcess(url, playerUID, cb) {
		let generatingProcessInterval = setInterval(() => {
			axios
				.request({
					method: "GET",
					url: url,
					headers: {
						'Authorization': `${authType} ${authToken}`,
						'X-PlayerUID': playerUID,
						"Content-Type": "multipart/form-data"
					}
				})
				.then(res => {
					// progress from the response seems to be broken, only return 0% until the certain point and then straight to 95% and 100%
					// this.progressRef.current.innerHTML = res.data.progress + "%";
					console.log(res.data.status, res.data.progress + "%", res)

					if (res.data.status === "Completed") {
						clearInterval(generatingProcessInterval);

						let userData = {
							avatar: {
								id: res.data.code,
								userID: playerUID
							}
						}
						console.log("final data", userData, userData.avatar.id)

						this.getAvatarModelInfo(userData.avatar.id, userData.avatar.userID, (modelInfo) => {
							// console.log("got model info", modelInfo);
							userData.avatar.bodyType = modelInfo.gender !== "male" ? 1 : 0;
							cb(userData);
						});
					} else if (res.data.status === "Failed") {
						console.log("failed", res.data.status, res);
						clearInterval(generatingProcessInterval);
						cb(null);
					}
				}).catch(error => {
					console.log("error", error);
					clearInterval(generatingProcessInterval);
				});
		}, 3000);
	}

	static getAvatarThumbnail(avatarCode, playerUID, cb) {
		if (!avatarCode) return;
		let authPromise = this.authorize();

		authPromise.then(() => {
			axios
				.request({
					method: "GET",
					url: "https://api.avatarsdk.com/avatars/" + avatarCode + "/thumbnail/",
					headers: {
						'Authorization': `${authType} ${authToken}`,
						'X-PlayerUID': playerUID,
						"Content-Type": "multipart/form-data"
					}
				})
				.then(res => {
					// console.log("model info", res.data, res)
					// const image = Buffer.from(res.data, 'binary').toString('base64')
			        // const base64 = btoa(
			        //   new Uint8Array(res.data).reduce(
			        //     (data, byte) => data + String.fromCharCode(byte),
			        //     '',
			        //   ),
			        // );
					const image = `data:image;base64,${res.data}`;
					console.log("image", image)
					cb(image);
				})
				.catch(error => {
					console.log("avatar model thumbnail error", avatarCode, error);
					cb(null);
				});
		});
	}

	static getAvatarModelInfo(avatarCode, playerUID, cb) {
		if (!avatarCode) return;
		let authPromise = this.authorize();

		authPromise.then(() => {
			axios
				.request({
					method: "GET",
					url: "https://api.avatarsdk.com/avatars/" + avatarCode + "/model_info/",
					headers: {
						'Authorization': `${authType} ${authToken}`,
						'X-PlayerUID': playerUID,
						"Content-Type": "multipart/form-data"
					}
				})
				.then(res => {
					// console.log("model info", res.data, res)
					cb(res.data);
				})
				.catch(error => {
					console.log("avatar model info error", avatarCode, error);
					cb(null);
				});
		});
	}

	static getAvatar(avatarCode, playerUID, cb) {
		let authPromise = this.authorize();

		authPromise.then(() => {
			// console.log("auth bear", authToken);

			if (!authToken) {
				console.warn('Empty authorization bearer');
				return cb(null);
			}

			if (!avatarCode) {
				console.log('Empty avatar code');
				return cb(null);
			}

			// cleanup();

			const textureUrl = [API_ROOT, 'avatars', avatarCode, 'texture/'].join('/');
			const meshUrl = [API_ROOT, 'avatars', avatarCode, 'mesh/?lod=5'].join('/');
			const blendshapesUrl = [API_ROOT, 'avatars', avatarCode, 'blendshapes/?fmt=ply&lod=5'].join('/');

			const hairTextureUrl = [API_ROOT, 'avatars', avatarCode, 'haircuts/generated/texture/'].join('/');
			const hairMeshUrl = [API_ROOT, 'avatars', avatarCode, 'haircuts/generated/mesh/?lod=7'].join('/');

			// resetProgress(textureUrl, meshUrl);

			const fileLoader = new AvatarSDKLoaders.FileLoader(authToken, playerUID);

			// --- retrieve texture ----------------------------------------------------
			const texturePromise = new Promise((resolve, reject) => {
				new AvatarSDKLoaders.TextureLoader(fileLoader).load(textureUrl, function onTextureLoaded(img) {
					texture = img;
					resolve(texture);
				}, this.onProgress, this.onError);
			});

			// --- retrieve mesh -------------------------------------------------------
			const meshPromise = new Promise((resolve, reject) => {
				new AvatarSDKLoaders.GeometryLoader(fileLoader).load(meshUrl, 'model.ply', function onGeometryLoaded(geometry) {
					mesh = geometry;
					mesh.name = "head"
					resolve(mesh);
				}, this.onProgress, this.onError);
			});

			// --- retrieve hair texture ----------------------------------------------------
			const hairTexturePromise = new Promise((resolve, reject) => {
				new AvatarSDKLoaders.TextureLoader(fileLoader).load(hairTextureUrl, function onTextureLoaded(img) {
					texture = img;
					resolve(texture);
				}, this.onProgress, reject);
			}).catch((error) => {
				console.log("dude could be bald")
			});

			// --- retrieve hair mesh -------------------------------------------------------
			const hairMeshPromise = new Promise((resolve, reject) => {
				// console.log("promise me")
				new AvatarSDKLoaders.GeometryLoader(fileLoader).load(hairMeshUrl, 'hair.ply', function onGeometryLoaded(geometry) {
					mesh = geometry;
					mesh.name = "hair"
					resolve(mesh);
				}, this.onProgress, reject);
			}).catch((error) => {
				console.log("dude could be bald")
			});

			// --- retrieve blendshapes -------------------------------------------------------
			// https://developer.apple.com/documentation/arkit/arfaceanchor/blendshapelocation
			const blendshapePromise = new Promise((resolve, reject) => {
				// dont load blendshapes for snap
				if (navigator.userAgent === "snap") {
					resolve();
				} else {
					new AvatarSDKLoaders.GeometryLoader(fileLoader).load(
						blendshapesUrl,
						this.blendshapeArrayWithExtension('.ply'),
						function onGeometryLoaded(geometry) {
							// console.log("blendshape geo", geometry);
							geometry = geometry || [];
							// this removes all named attributes for some reason
							let blendshapeGeometry = geometry.map((g) => g.toNonIndexed());

							// console.log("geo", geometry, blendshapeGeometry)
							resolve(blendshapeGeometry);
						}, this.onProgress, this.onError);
				}
			});

			const meshPromises = [texturePromise, meshPromise, hairTexturePromise, hairMeshPromise, blendshapePromise];

			Promise.all(meshPromises).then(resolvedData => {
				// console.log("resolved promiseAll", resolvedData);

				const faceTexture = resolvedData[0];
				const faceGeometry = resolvedData[1];
				const hairTexture = resolvedData[2];
				const hairGeometry = resolvedData[3];
				const blendshapeGeometry = resolvedData[4]


				// setup face
				faceTexture.encoding = THREE.GammaEncoding;

				let faceMaterialArgs = {
					color: 0xffffff,
					side: THREE.DoubleSide,
					morphTargets: true,
					// roughness: .5,
					// metalness: 0,
					// skinning: true,
					map: faceTexture
				};

				let faceMaterial = new THREE.MeshBasicMaterial(faceMaterialArgs);

				// compute head size then scale accordingly to normalize
				faceGeometry.computeBoundingBox();
				const faceSize = new THREE.Vector3()
				faceGeometry.boundingBox.getSize(faceSize);

				// scale up geometries
				const xScaleFactor = (48 / 2.5) / faceSize.x;
				const zScaleFactor = 23 / faceSize.z;
				const scaleFactor = Math.min(xScaleFactor, zScaleFactor)

				// console.log("size", faceSize, zScaleFactor);
				// console.log("scale", scaleFactor, xScaleFactor, zScaleFactor);
				faceGeometry.scale(scaleFactor, scaleFactor, scaleFactor);

				// start blendshapes
				// blendshape sizing
				if (blendshapeGeometry) {
					if (Array.isArray(blendshapeGeometry)) {
						blendshapeGeometry.forEach((g) => g.scale(scaleFactor, scaleFactor, scaleFactor));
					} else {
						blendshapeGeometry.scale(scaleFactor, scaleFactor, scaleFactor);
					}

					// blendshape mapping
					if (Array.isArray(blendshapeGeometry)) {
						faceGeometry.morphAttributes.position = blendshapeGeometry.map((g) => g.attributes.position);
					} else {
						faceGeometry.morphAttributes.position = [];
						faceGeometry.morphAttributes.position[0] = blendshapeGeometry.attributes.position;
					}
				}

				// geometry = new THREE.BufferGeometry().fromGeometry( geometry );
				const baseMesh = new THREE.Mesh(faceGeometry, faceMaterial);
				// baseMesh.updateMorphTargets();

				baseMesh.traverse( child => {
					if (child.isMesh) {
						// console.log('mesh', child.name)
						child.castShadow = true;
						child.receiveShadow = true;
					}
				});

				baseMesh.position.set(0, 13.5, 5.25);

				// hair stuff, note bold people return no hairgeometry
				if (hairGeometry && hairTexture) {
					hairTexture.encoding = THREE.GammaEncoding;
					let hairMaterialArgs = {
						color: 0xffffff,
						// roughness: .5,
						// metalness: 0,
						// side: THREE.DoubleSide,
						// morphTargets: true,
						transparent: true,
						map: hairTexture
					};
					let hairMaterial = new THREE.MeshBasicMaterial(hairMaterialArgs);

					hairGeometry.scale(scaleFactor, scaleFactor, scaleFactor);
					let hairMesh = new THREE.Mesh(hairGeometry, hairMaterial);

					hairMesh.traverse( child => {
						if (child.isMesh) {
							// console.log('mesh', child)
							// child.castShadow = true;
							// child.receiveShadow = true;
						}
					});
					baseMesh.add(hairMesh);
				}

				//ADD CUBE for help
				// const boxGeometry = new THREE.BoxGeometry(14, 28, 12);
				// const boxMaterial = new THREE.MeshBasicMaterial({ color: '#433F81' });
				// const cubeHead = new THREE.Mesh(boxGeometry, boxMaterial);
				// cubeHead.position.set(0, 0, -4);

				// const shoulderGeometry = new THREE.BoxGeometry(14*3, 14, 12);
				// const shoulderMaterial = new THREE.MeshBasicMaterial({ color: '#ff4444' });
				// const cubeShoulder = new THREE.Mesh(shoulderGeometry, shoulderMaterial);
				// cubeShoulder.position.set(0, -4, -4);

				// baseMesh.add( cubeHead, cubeShoulder );

				// console.log('return basemesh');
				baseMesh.userData = {scaleFactor: scaleFactor}
				cb(baseMesh);
			}).catch(error => {
				console.log("avatarSDK basemesh error", error);
			});

		})
	}

	// === service functions =====================================================
	static onError(data) {
		return console.warn("service error", data)
	}


	static onProgress(event) {
		// console.log("onprogress")
		_progress[event.target.responseURL] = event.loaded / event.total;

		// let values = Object.values(_progress);
		// let progress = values.reduce(function (a,i){return a+i;}, 0) / values.length;

		// progress_container.innerHTML = Math.floor(progress * 100);
	}


	static cleanup() {
		if (!!mesh) {
			this.scene.remove(mesh);
			mesh = null;
		}

		if (!!texture) {
			texture = null;
		}
	}

	static resetProgress(textureUrl, meshUrl) {
		// progress_container.innerHTML = '0';
		_progress[textureUrl] = 0;
		_progress[meshUrl] = 0;
	}

	static blendshapeArray = [
		"jawOpen",

		"eyeBlinkLeft",
		"eyeBlinkRight",

		"mouthSmileLeft",
		"mouthSmileRight",
		"mouthFrownLeft",
		"mouthFrownRight",
		"mouthFunnel",
		"mouthPucker",
		"mouthLeft",
		"mouthRight",

		"browInnerUp",

		"eyeLookUpLeft",
		"eyeLookOutLeft",
		"eyeLookDownLeft",
		"eyeLookInLeft",

		"eyeLookUpRight",
		"eyeLookOutRight",
		"eyeLookDownRight",
		"eyeLookInRight"
	];

	static blendshapeArrayWithExtension(string) {
		let newBlendshapeArray = [];

		this.blendshapeArray.forEach( (key, i) => {
			const newKey = `${key}${string}`
			newBlendshapeArray.push(newKey);
		})

		return newBlendshapeArray;
	};

	static blendshapes() {
		let blendshapes = {};
		this.blendshapeArray.forEach( (key, i) => {
			blendshapes[key] = i;
		})
		// console.log(blendshapes)
		return blendshapes
	};
}

export default AvatarSDK;