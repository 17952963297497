import * as React from "react";

import * as THREE from 'three';
// import { ARButton } from 'three/examples/jsm/webxr/ARButton.js';

import Stats from 'stats.js';
import Utils from './Utils.js';

import TeleportControls from './TeleportControls'
import ObjectControls from './ObjectControls'

import SceneBackground from './SceneBackground.js';
import SceneAvatar from './SceneAvatar.js';

import API from "./API.js";
import TWEEN from '@tweenjs/tween.js'

const raycaster = new THREE.Raycaster();
const mouse = new THREE.Vector2();
let moved, dragged;
let initialAvatarPosition

let targetCameraHeight = 130;

class Scene extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			init: false,
			status: null,
			cameraTarget: 0,
			avatars: {},
			faceCamera: true,
			renderedAvatars: {},
			mousePosition: null
		};
	}

	componentDidMount() {
		console.log('scene mountin');

		this.tweenCameraGroup = new TWEEN.Group();

		THREE.Cache.enabled = true;

		this.threeStats = new Stats();
		this.threeStats.dom.id = 'StatsDiv';
		this.threeStats.dom.style.position = null;
		if (this.props.showDebug) {
			this.refs.stats.appendChild(this.threeStats.dom);
			// this.threeStats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
		}

		const width = this.mount.clientWidth
		const height = this.mount.clientHeight

		//ADD SCENE
		this.mixer = null;
		this.clock = new THREE.Clock();
		this.contentGroup = null;

		this.scene = new THREE.Scene();
		// console.log("scene ready");

		//ADD CAMERA
		this.camera = new THREE.PerspectiveCamera(35, width / height, 50, 100000);
		// this.camera.position.set(-800, 1400, -1400);
		// this.camera.near = 1;
		// this.helper = new THREE.CameraHelper( this.camera );
		// this.scene.add( this.helper );

		// this.camera.position.set( 0, -100, 0 );
		// this.camera.zoom = .001;

		//ADD RENDERER
		this.renderer = new THREE.WebGLRenderer( { alpha: true, antialias: true } );
		// this.renderer.setClearColor('#000000')
		this.renderer.setPixelRatio( Math.min(window.devicePixelRatio, 1.5) );
		// console.log("pixel ratio", window.devicePixelRatio, window.innerWidth, window.innerHeight)
		// this.renderer.setPixelRatio(window.devicePixelRatio/2);
		this.renderer.setSize(width, height);
		// this.renderer.xr.enabled = true;
		this.renderer.outputEncoding = THREE.sRGBEncoding;

		this.renderer.shadowMap.enabled = true;
		this.renderer.domElement.id = "ThreeCanvas"
		this.mount.appendChild(this.renderer.domElement)

		// document.body.appendChild( ARButton.createButton( this.renderer, { requiredFeatures: [ 'hit-test' ] } ) );
		// console.log('png', this.renderer.xr)

		// controls
		this.controls = new TeleportControls(this.camera, this.renderer.domElement);
		this.toggleMovement()
		// // test box move
		// const material = new THREE.MeshNormalMaterial();
		// const geo = new THREE.BoxGeometry( 50, 50, 10 );
		// const box = new THREE.Mesh( geo, material );
		// box.position.y = 200
		// this.scene.add(box)

		this.objectControls = new ObjectControls([], this.camera, this.renderer.domElement, this.controls)

		// init
		this.start()

		// if there's a bg, go to normal orbit for splash
		this.sceneBG = new SceneBackground(this.scene, this.props.bg, this.props.bgColor);

		if (this.props.bg) {
			// console.log("props bg", this.props);
			targetCameraHeight = 0;

			this._autoRotateScene();
			this.goToDollhouse();
		} else {
			targetCameraHeight = 130;
			this.goToDollhouse();
		}

		if (this.props.autoRotate) {
			this._autoRotateScene(2);
		}

		// add resize
		window.addEventListener( 'resize', this.onWindowResize, false );
	}

	componentDidUpdate(prevProps, prevStates) {

		// console.log(this.props.people, prevProps.people)
		if (this.props.people !== prevProps.people && this.props.people) {
			console.log("people change props", this.props.people);
			Object.keys(this.state.renderedAvatars).map(this._removeSceneAvatar);
			Object.keys(this.props.people).map(this._createSceneAvatar);


			// this is really good auto camrea to auto fit all peopel that join
			// console.log('people prop changed', Object.keys(this.props.people).length)

			if (Utils.isUserAgent("snap")) {
				// if its the snapper
				this.goToSnap();
			} else {
				// this auto cameras based on people in
				if (Object.keys(this.props.people).length > 1) {
					this.goToDollhouse(Object.keys(this.props.people).length);
				} else {
					// this.goToSecondPerson(true);
					this.goToDollhouse();
				}
			}
		}

		if (this.props.content !== prevProps.content && this.props.content) {
			this._createSceneContent(this.props.content);
		}

		if (this.state.status !== prevStates.status && this.state.status) {
			this.props.parentStatusMethod(this.state.status);
		}

		if (this.props.showDebug !== prevProps.showDebug) {
			const span = document.getElementById("StatsDiv");

			if (this.threeStats.dom.contains(span)) {
				this.refs.stats.removeChild(this.threeStats.dom);
			} else {
				this.refs.stats.appendChild(this.threeStats.dom);
			}
		}

		if (this.props.match.params.type !== prevProps.match.params.type) {
			// console.log("fart", Object.keys(this.props.people).length)

			// if (this.props.match.params.type === "chat" && Object.keys(this.props.people).length > 1) {
			// 	console.log("go to dollhouse", Object.keys(this.props.people).length)
			// 	this.goToDollhouse(Object.keys(this.props.people).length);
			// } else {
			// 	this.goToPortrait();
			// }

		}
	}

	componentWillUnmount() {
		console.log('scene unmounting');
		// this.props.parentStatusMethod("unmount crash")
		// this.stop();
	}

	_removeSceneContent = () => {
		if (this.contentGroup) {
			// this.contentGroup.remove(...this.contentGroup.children);

			this.contentGroup.children.forEach(child => {

				// new TWEEN.Tween( box.rotation)
				// 	.to( newBoxRotation, 3000 )
				// 	.easing(TWEEN.Easing.Exponential.Out)
				// 	.start();
				const newBoxScale = new THREE.Vector3( 0, 0, 0)
				const newBoxPosition = new THREE.Vector3(child.position.x, child.position.y, child.position.z - Utils.randomNumber(200, 800));

				new TWEEN.Tween( child.position)
					.to( newBoxPosition, Utils.randomNumber(500, 2000))
					.easing(TWEEN.Easing.Exponential.Out)
					.start();
				new TWEEN.Tween( child.scale)
					.to( newBoxScale, Utils.randomNumber(500, 2000))
					.easing(TWEEN.Easing.Exponential.Out)
					.onComplete(() => {
						this.contentGroup.remove(child)
					})
					.start();
			})


		}
	}
	_createSceneContent = (content) => {
		this._removeSceneContent()

		const wall = {
			width: 1280,
			height: 320,
			x: 0,
			y: 100,
			z: -150
		}

		const contentArray = Object.values(content)
		const bbox = {}

		bbox.smallestLayerX = contentArray.reduce((prev, curr) => {
			// console.log("bbox", prev.x < curr.x ? prev.x : curr.x)
			return prev.x < curr.x ? prev : curr;
		})
		bbox.x = bbox.smallestLayerX.x

		bbox.smallestLayerY = contentArray.reduce((prev, curr) => {
			return prev.y < curr.y ? prev : curr;
		})
		bbox.y = bbox.smallestLayerY.y

		bbox.largestLayerX = contentArray.reduce((prev, curr) => {
			return prev.x + prev.width > curr.x + curr.width ? prev : curr;
		})
		bbox.width = bbox.largestLayerX.x + bbox.largestLayerX.width - bbox.x

		bbox.largestLayerY = contentArray.reduce((prev, curr) => {
			return prev.y + prev.height > curr.y + curr.height ? prev : curr;
		})
		bbox.height = bbox.largestLayerY.y + bbox.largestLayerY.height - bbox.y


		const scaleFactorX = wall.width / bbox.width
		const scaleFactorY = wall.height / bbox.height
		const scaleFactor = Math.min(scaleFactorX, scaleFactorY)

		// console.log("scaleFactor", scaleFactorX, scaleFactorY, scaleFactor)

		if (!this.contentGroup) {
			this.contentGroup = new THREE.Group();
			this.contentGroup.name = "ContentGroup"

			this.scene.add(this.contentGroup);
		}

		this.contentGroup.scale.set(scaleFactor, scaleFactor, scaleFactor)
		this.contentGroup.position.x = (-bbox.x - bbox.width/2) * scaleFactor
		this.contentGroup.position.y = (-bbox.y * scaleFactor ) + wall.y
		this.contentGroup.position.z = wall.z
		// // // test wall
		// const material = new THREE.MeshNormalMaterial();
		// const wallGeometry = new THREE.BoxGeometry( wall.width, wall.height, 1 );
		// const box = new THREE.Mesh( wallGeometry, material );
		// box.position.y = box.geometry.parameters.height / 2 + wall.y
		// box.position.z = box.position.z / 2 + wall.z - 20
		// // console.log("boxheight", box.geometry.parameters.height)
		// this.scene.add(box)


		Object.keys(content).forEach( key => {
			const data = content[key];
			// console.log("content url", data.url)
			new THREE.ImageLoader()
				.setCrossOrigin('*')
				.load( data.url + '?' + performance.now(), (image) => {
					// console.log("done adding", image)
					const texture = new THREE.CanvasTexture(image);
					const geometry = new THREE.BoxGeometry( data.width, data.height, 1 );
					const material = new THREE.MeshBasicMaterial( { color: 0xffffff, map: texture, transparent: true} );

					const box = new THREE.Mesh( geometry, material );
					box.position.set(data.x + data.width/2, data.y + data.height/2, Utils.randomNumber(-3000, 3000));
					box.scale.set(0, 0, 0)
					this.contentGroup.add(box)
					this.objectControls.add(box)

					// animate in box art
					const newBoxPosition = new THREE.Vector3(box.position.x, box.position.y, Utils.randomNumber(-100, 100));
					// const newBoxRotation = new THREE.Vector3( 0, Math.PI, 0)
					const newBoxScale = new THREE.Vector3( 1, 1, 1)

					new TWEEN.Tween( box.position)
						.to( newBoxPosition, Utils.randomNumber(1000, 3000) )
						.easing(TWEEN.Easing.Exponential.Out)
						.start();

					// new TWEEN.Tween( box.rotation)
					// 	.to( newBoxRotation, 3000 )
					// 	.easing(TWEEN.Easing.Exponential.Out)
					// 	.start();

					new TWEEN.Tween( box.scale)
						.to( newBoxScale, Utils.randomNumber(500, 2000))
						.easing(TWEEN.Easing.Exponential.Out)
						.start();
				});
		})
	}


	_createSceneAvatar = (key, i, array) => {
		let avatars = this.state.avatars;
		let renderedAvatars = this.state.renderedAvatars;
		// console.log("create scene avatar", key, this.state.avatars)
		if (!this.state.avatars[key]) {
			// if it doesn't exist, create it and add to scene
			// console.log("creating avatar", key, this.props.people)
			avatars[key] = new SceneAvatar(this.props.people[key], this.props.parentStatusMethod);

			avatars[key].init( (mesh) => {
				avatars[key].mesh = mesh;
				this.scene.add(avatars[key].mesh);
				renderedAvatars[key] = avatars[key];
				this.setState({
					avatars: avatars,
					renderedAvatars: renderedAvatars
				});

				this.repositionAvatar(avatars[key], i, array.length);
				this.props.parentStatusMethod("loading yo face", "fadePulse");
			});
		} else {
			// if it already exists in avatars, just add it to the scene and renderedAvatars
			if (this.state.avatars[key].mesh && !this.state.renderedAvatars[key]) {
				this.scene.add(this.state.avatars[key].mesh);
				this.state.avatars[key].createLabel()
				renderedAvatars[key] = this.state.avatars[key];

				this.setState({
					renderedAvatars: renderedAvatars
				}, () => {
					this.repositionAvatar(this.state.renderedAvatars[key], i, array.length);
				});
			} else if (this.state.renderedAvatars[key]) {
				// console.log('reposition doesn')
				this.repositionAvatar(this.state.renderedAvatars[key], i, array.length);
			}
		}

		// todo: find a way to make updated avatars trans
		// turning off cause this cause it doesn't really work
		// if (this.state.avatars[key].mesh && this.props.people[key]) {
		// 	if (this.props.people[key].transparent === true) {
		// 		console.log("set skin trans")
		// 		this.state.avatars[key].transparent = true
		// 		this.state.avatars[key]._updateSkin(true)
		// 	} else if (this.props.people[key].transparent === false) {
		// 		console.log("set skin reset")
		// 		this.state.avatars[key].transparent = false
		// 		this.state.avatars[key]._updateSkin(false)
		// 	}
		// }
	}

	_removeSceneAvatar = (key, i) => {
		// if this avatar doesn't exist in the new people state, get rid of it
		// or if I don't exist
		// console.log("remove scene avatar", this.props.people[key], this.state.renderedAvatars[key])
		if (!this.props.people[key]) {
			// console.log("removing avatar", this.state.renderedAvatars[key])
			this.state.renderedAvatars[key].removeLabel()
			this.scene.remove( this.state.renderedAvatars[key].mesh );

			// reset avatars
			let renderedAvatars = this.state.renderedAvatars;
			delete renderedAvatars[key];
			this.setState({ renderedAvatars });
		}
	}

	_autoRotateScene = (speed=4) => {
		this.controls.autoRotate = true;
		this.controls.autoRotateSpeed = speed;
		this.controls.enablePan = false;
		this.controls.minPolarAngle = Math.PI * .2;
		this.controls.maxPolarAngle = Math.PI / 1.4;
	}

	toggleMovement = (allowMovement) => {
		this.controls.addEventListener( 'change', this.handleCameraMovement ); // call this only in static scenes (i.e., if there is no animation loop)



		// window.addEventListener( 'pointerup', function (event) {
		// 	this.mount.style.cursor = 'grab';
		// 	console.log("mouse up")
		// })
		// if (allowMovement) {
		// 	this.goToThirdPerson();
		// } else {
		// 	this.goToSecondPerson();
		// }
	}

	handleCameraMovement = (e) => {
		// console.log('handling', this.controls.target, this.state.renderedAvatars)
		if (this.state.renderedAvatars[API.getCurrentPhoneNumber()]) {
			this.state.renderedAvatars[API.getCurrentPhoneNumber()].mesh.position.set(this.controls.target.x, 0, this.controls.target.z)
		}

		// this.controls.target.y = Utils.mapRange(this.controls.distance(), this.controls.minDistance, this.controls.maxDistance, targetCameraHeight, 0);
		// this.camera.fov = Utils.mapRange(this.controls.distance(), this.controls.minDistance, this.controls.maxDistance, 100, 80);
		// this.camera.updateProjectionMatrix();

		// handle camera distance
		this.camera.near = Utils.modulate(this.controls.distance(), [this.controls.minDistance, this.controls.maxDistance], [1, this.controls.maxDistance/2])
		this.camera.fov = Utils.modulate(this.controls.distance(), [this.controls.minDistance, this.controls.maxDistance], [35, 22])
		this.camera.updateProjectionMatrix();

	}

	repositionAvatar(avatarData, i, length) {
		let layout

		switch (length) {
			case 0:
			case 1:
				layout = "solo"
				break
			case 2:
			case 3:
			case 4:
			case 5:
				layout = "pile"
				break
			default:
				layout = "squad"
		}

		// console.log("layout", layout, length)
		// position avatars in a circle so they don't overlap
		// console.log("reposition avatar mesh", avatarData, i, length);

		let avatarShoulderDepth = 24;
		let avatarShoulderWidth = 120;
		let	avatarShoulderHeight = 8;
		let avatarHeadHeight = 20;

		let radius = Math.max(50, (avatarShoulderWidth * length)/(2 * Math.PI));
		let rotation = ((2 * Math.PI) * (i/length));

		// if just 1 person set radius to 0
		if (layout === "solo") {
			return
		} else if (layout === "pile") {
			avatarShoulderDepth = 24;
			avatarShoulderWidth = 24;
			avatarShoulderHeight = 2;

			let rowHeight = (Math.round((i+1)/2) * avatarHeadHeight);
			let rowDepth = (Math.round((i+1)/2) * avatarShoulderDepth/2);


			if (Utils.isEven(i)) {
				avatarData.mesh.position.x = avatarShoulderWidth / -2;
			} else {
				avatarData.mesh.position.x = avatarShoulderWidth / 2;
			}
			// console.log("iteration", i, i % 2, Math.round((i+1)/2))

			avatarData.mesh.position.y = rowHeight + (avatarShoulderHeight * i);
			avatarData.mesh.position.z = rowDepth + (-avatarShoulderDepth * i) + ((avatarShoulderDepth * (length-1))/3);

			avatarData.mesh.rotation.y = Utils.randomNumber(-Math.PI/8, Math.PI/8);
		} else if (layout === "squad") {
			// fermat's spiral crowd
			let theta = 2.39998131 * i;
			let radius = 16 * Math.sqrt(theta);
			avatarData.mesh.position.x = Math.cos(theta) * radius;
			avatarData.mesh.position.y = 60 + Math.sin(theta) * -radius/2;
			avatarData.mesh.position.z = Math.sin(theta) * radius;

			avatarData.mesh.rotation.y = Utils.randomNumber(-Math.PI/8, Math.PI/8);
		} else if (layout === "roundtable") {
			avatarShoulderWidth = 0;
			radius = Math.max(50, (avatarShoulderWidth * length)/(2 * Math.PI));

			avatarData.mesh.position.x = Math.cos(rotation) * radius;
			avatarData.mesh.rotation.y = Utils.randomNumber(0, Math.PI/2);
			avatarData.mesh.position.z = Math.sin(rotation) * radius;
		} else {
			// just do an inner circle
			avatarData.mesh.position.x = Math.cos(rotation) * radius;
			avatarData.mesh.rotation.y = Math.PI - rotation + (Math.PI/2);
			avatarData.mesh.position.z = Math.sin(rotation) * radius;
			// console.log("avatar rotation", rotation, length, radius, avatarData.mesh.position, avatarData.mesh.rotation)
		}

	}

	start = () => {
		if (!this.frameId) {
			this.frameId = requestAnimationFrame(this.animate)
		}
	}

	stop = () => {
		cancelAnimationFrame(this.frameId)

		if (this.renderer.domElement) {
			this.mount.removeChild(this.renderer.domElement)
		}
	}

	animate = () => {
		if (this.props.showDebug) {
			this.threeStats.begin();
		}
		this.controls.update(); // only required if controls.enableDamping = true, or if controls.autoRotate = true

		// update mixer for glb animations
		let delta = this.clock.getDelta();

		if (this.sceneBG) {
			this.sceneBG.updateMixer(delta);
		}

		// update avatar label positions in screenspace
		if (this.state.renderedAvatars) {
			// console.log("labeling")
			Object.keys(this.state.renderedAvatars).map((key, i, array) => {
				// handle labels
				if (document.querySelector('#avatarLabels') && this.mount) {
					// position labels see: https://threejsfundamentals.org/threejs/lessons/threejs-align-html-elements-to-3d.html
					const labelVector = new THREE.Vector3();
					const labelOffsetVector = new THREE.Vector3(0, 24, 0);

					// get the position of the center of the cube
					// console.log(this.state.renderedAvatars[key].mesh)
					this.state.renderedAvatars[key].head.updateWorldMatrix(true, false);
					this.state.renderedAvatars[key].head.getWorldPosition(labelVector).add(labelOffsetVector);

					// get the normalized screen coordinate of that position
					// x and y will be in the -1 to +1 range with x = -1 being
					// on the left and y = -1 being on the bottom
					labelVector.project(this.camera);

					// convert the normalized position to CSS coordinates
					const x = Utils.round((labelVector.x *  .5 + .5) * this.mount.clientWidth);
					const y = Utils.round((labelVector.y * -.5 + .5) * this.mount.clientHeight);

					// move the elem to that position

					if (this.state.renderedAvatars[key].label) {
						// const newTransformPosition = `translate(-50%, -50%) translate(${x}px,${y}px)`
						// console.log('label', newTransformPosition, this.state.renderedAvatars[key].label.style.transform)
						this.state.renderedAvatars[key].label.style.transform = `translate(-50%, -50%) translate(${x}px,${y}px)`
					}
				}

				// this.state.renderedAvatars[key].animateFace(this.clock.oldTime);
				return this.state.renderedAvatars[key].updateAnimations(delta);
			});
		}

		// update tween/mixer animations
		TWEEN.update();
		if (this.tweenCameraGroup) this.tweenCameraGroup.update();

		this.renderScene();

		if (this.props.showDebug) {
			// monitored code goes here
			this.threeStats.end();
		}

		this.frameId = this.renderer.setAnimationLoop(this.animate);
	}

	renderScene = () => {
		this.renderer.render(this.scene, this.camera);
	}

	onWindowResize = () => {
		// console.log("this mount", this.mount, this.mount.clientWidth, this.mount.clientHeight);
		if (this.mount) {
			var width = this.mount.clientWidth;
			var height = this.mount.clientHeight;

			this.camera.aspect = width / height;
			this.camera.updateProjectionMatrix();

			this.renderer.setSize( width, height );
		}
	}

	render(){
		return(
				<div
					className="full"
					ref={(mount) => { this.mount = mount }}
					onPointerDown={this.getMouseDown.bind(this)}
					onPointerUp={this.getMouseUp.bind(this)}
					onPointerMove={this.getMouseMove.bind(this)}
					// onMouseLeave={this.resetMousePosition.bind(this)}
				>
					<div
						ref="stats"
						className="fixed left-0 bottom-0 mb2 ml2"
					>
					</div>
				</div>
		)
	}

	getMouseDown = (event) => {
		moved = false;
		dragged = true;
		// console.log('down', moved)
		// overide cursor from object controls
		this.renderer.domElement.style.cursor = 'grabbing';
		initialAvatarPosition = this.controls.target.clone()
	}

	getMouseUp = (event) => {
		dragged = false;
		// console.log('up', moved)
		// overide cursor from object controls
		this.renderer.domElement.style.cursor = 'grab';
		if (moved) {
			// console.log('moved / dragged')
		} else {
			// update the picking ray with the camera and mouse position
			mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
			mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

			raycaster.setFromCamera(mouse, this.camera);

			// calculate objects intersecting the picking ray
			const intersects = raycaster.intersectObjects(this.scene.children);

			if (intersects[0] && !this.objectControls.isHovering) {
				if (this.objectControls.isLightbox) {
					// if its lightbox exit it
					this.goToDollhouse(4)
					this.objectControls.isLightbox = false
				} else {
					let targetPosition = new THREE.Vector3(intersects[0].point.x, targetCameraHeight, intersects[0].point.z)
					let cameraPosition = new THREE.Vector3(this.camera.position.x, this.camera.position.y, this.camera.position.z)

					const angle = Utils.getAngleTo(this.controls.target, targetPosition)
					// console.log(angle)

					//ADD test CUBE
					const boxGeometry = new THREE.ConeGeometry(8, 20, 12);
					const boxMaterial = new THREE.MeshNormalMaterial();
					let cube = new THREE.Mesh(boxGeometry, boxMaterial);
					cube.position.set(targetPosition.x, 40, targetPosition.z);
					cube.scale.set(0, 0, 0);

					cube.rotateY( angle );
					cube.rotateX( Math.PI/2 );
					// cube.rotation.set(angle, angle, angle)
					this.scene.add(cube);

					const newBoxScaleUp = new THREE.Vector3(1, 1, 1)
					const newBoxScaleDown = new THREE.Vector3(0, 0, 0)

					const scaleUp = new TWEEN.Tween(cube.scale)
						.to( newBoxScaleUp, 800)
						.easing(TWEEN.Easing.Exponential.Out)
						.onComplete(() => {
							const scaleDown = new TWEEN.Tween(cube.scale)
								.to( newBoxScaleDown, 500)
								.easing(TWEEN.Easing.Exponential.Out)
								.onComplete(() => {
									this.scene.remove(cube)
								}).start()
						})
						.start();

					this._tweenCamera(cameraPosition, targetPosition, 2000);

					const me = this.state.renderedAvatars[API.getCurrentPhoneNumber()]

					if (me) {
						me._tweenPoint(me.body.rotation, {x: 0, y: angle, z:0})
					}
				}

			}

		}


		// for ( let i = 0; i < intersects.length; i ++ ) {
		// 	intersects[ i ].object.material.color.set( 0xff0000 );
		// }
	}

	getMouseMove = (event) => {
		moved = true

		// angle towards a drag
		const me = this.state.renderedAvatars[API.getCurrentPhoneNumber()]
		if (dragged && me && initialAvatarPosition) {
			const newAvatarPosition = this.controls.target.clone()

			const delta = newAvatarPosition.distanceTo(initialAvatarPosition)

			if (delta > 10) {
				const angle = Utils.getAngleTo(initialAvatarPosition, newAvatarPosition)
				const angleThree = new THREE.Vector3(0, angle, 0)
				// me.body.rotation.y = angle
				new TWEEN.Tween( me.body.rotation)
					.to( angleThree, 500)
					.easing(TWEEN.Easing.Exponential.Out)
					.start()

				// console.log("angle", angle, delta)
			}
		}

		// const mousePosition = {
		// 	x: Utils.modulate(event.screenX, [0, this.mount.clientWidth], [-1, 1], true),
		// 	y: Utils.modulate(event.screenY, [0, this.mount.clientHeight], [-1, 1], true)
		// };

		// mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
		// mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

		// this.setState({mousePosition})

		// // update the picking ray with the camera and mouse position
		// raycaster.setFromCamera( mouse, this.camera );

		// // calculate objects intersecting the picking ray
		// const intersects = raycaster.intersectObjects( this.scene.children );

		// if (intersects[0]) {
		// 	let targetPosition = new THREE.Vector3(intersects[0].point.x, targetCameraHeight, intersects[0].point.z)
		// 	let cameraPosition = new THREE.Vector3(this.camera.position.x, this.camera.position.y, this.camera.position.z)

		// 	let adjacent = targetPosition.z - this.controls.target.z
		// 	let opposite = targetPosition.x - this.controls.target.x
		// 	let angle = Math.atan2(opposite, adjacent);

		// 	//ADD test CUBE
		// 	// const boxGeometry = new THREE.ConeGeometry(10, 20, 12);
		// 	// const boxMaterial = new THREE.MeshNormalMaterial();
		// 	// let cube = new THREE.Mesh(boxGeometry, boxMaterial);
		// 	// cube.position.set( targetPosition.x, 0, targetPosition.z );

		// 	// cube.rotateY( angle );
		// 	// cube.rotateX( Math.PI/2 );
		// 	// // cube.rotation.set(angle, angle, angle)
		// 	// this.scene.add( cube );

		// 	console.log(angle)

		// 	// this._tweenCamera(cameraPosition, targetPosition, 2000);

		// 	const me = this.state.renderedAvatars[API.getCurrentPhoneNumber()]

		// 	if (me) {
		// 		// me._tweenPoint(me.body.rotation, {x: 0, y: angle, z:0})
		// 		// me.body.rotateY = angle
		// 		me.body.rotation.y = angle
		// 	}
		// }

	}

	resetMousePosition = (event) => {
		this.setState({mousePosition: null})
	}

	setAvatarLabelState = (uid, audioState, videoStream) => {
		if (uid && this.state.renderedAvatars[uid]) {
			if (this.state.renderedAvatars[uid].audioState !== audioState || this.state.renderedAvatars[uid].videoStream !== videoStream) {
				// console.log("creating label for avatar", audioState, videoStream)
				this.state.renderedAvatars[uid].createLabel(audioState, videoStream)
			}
		}
	}

	setTalkingAvatar = (uid, isTalking) => {
		if (uid && this.state.renderedAvatars[uid] && this.state.renderedAvatars[uid].talking !== isTalking) {
			this.state.renderedAvatars[uid].talking = isTalking
			this.state.renderedAvatars[uid].animateTalking(isTalking)
		}
	}

	setTalkingAvatars = (userVolumes) => {
		if (userVolumes) {
			Object.keys(userVolumes).map((key, i, array) => {
				// console.log(this.state.renderedAvatars[key].personData.firstName, userVolumes[key])
				if (this.state.renderedAvatars[key]) {
					if (userVolumes[key] > 0) {
						// console.log("talking", key, userVolumes[key])
						this.state.renderedAvatars[key].talking = true
					} else {
						// console.log("not talking", userVolumes[key])
						this.state.renderedAvatars[key].talking = false
					}
				}
			})
		}
	}

	setAvatarHeadPosition = (data) => {
		const myAvatar = this.state.renderedAvatars[API.getCurrentPhoneNumber()];
		data.mousePosition = this.state.mousePosition;

		// console.log("avatar", myAvatar, API.getCurrentPhoneNumber())

		if (myAvatar) {
			// console.log("my position", new THREE.Vector3().copy(myAvatar.mesh.position));
			// const targetRotation = this.state.faceCamera ? new THREE.Vector3(0, this.camera.rotation.y, 0) : new THREE.Vector3(0, this.camera.rotation.y + Math.PI, 0);

			data.global = {
				position: {
					x: 0,
					y: 0,
					z: 0
				},
				rotation: {
					x: 0,
					y: 0,
					z: 0
				}
			}

			// // console.log("peeps", Object.keys(this.props.people).length)
			// // console.log("me", this.controls.getAzimuthalAngle());
			// // only sync if there's more than 1 dude
			// if (Object.keys(this.props.people).length > 1) {
			// 	API.setCurrentAvatarMovement(data);
			// }

			myAvatar.animateFace(data);
		}
	}

	goToIntro = () => {
		console.log('going intro')
		this.setState({ faceCamera: false })
		// this.camera.position.set(1000, 1000, -1000);
		this.camera.position.set(-800, 1400, -1400);

		let cameraPosition = new THREE.Vector3(-60, 160, 60);
		let targetPosition = this.state.renderedAvatars[API.getCurrentUUID()] ? new THREE.Vector3(this.state.renderedAvatars[API.getCurrentUUID()].mesh.position.x, targetCameraHeight, this.state.renderedAvatars[API.getCurrentUUID()].mesh.position.z) : new THREE.Vector3(0, targetCameraHeight, 0);

		this._tweenCamera( cameraPosition, targetPosition, 2000 );
	}

	goToSecondPerson = (init, skip=false) => {
		console.log('going second')
		this.setState({ faceCamera: true })
		let	cameraPosition = new THREE.Vector3(-this.camera.position.x, 220, -this.camera.position.z)

		const headHeight = 160;

		if (init) {
			cameraPosition = new THREE.Vector3(0, headHeight, 70);
		}
		// let cameraPosition = new THREE.Vector3(350, 220, 0);
		let targetPosition = new THREE.Vector3(this.controls.target.x, headHeight, this.controls.target.z);
		// let targetPosition = this.controls.target;
		// console.log(targetPosition)

		this._tweenCamera( cameraPosition, targetPosition, skip ? 0 : 2000 );
	}

	goToThirdPerson = () => {
		console.log('going third')
		this.setState({ faceCamera: false })

		let cameraPosition = new THREE.Vector3(-60, 160, 60);
		let targetPosition = this.state.renderedAvatars[API.getCurrentUUID()] ? new THREE.Vector3(this.state.renderedAvatars[API.getCurrentUUID()].mesh.position.x, targetCameraHeight, this.state.renderedAvatars[API.getCurrentUUID()].mesh.position.z) : new THREE.Vector3(0, targetCameraHeight, 0);

		this._tweenCamera( cameraPosition, targetPosition, 2000 );
	}

	goToPortrait = () => {
		console.log('going to portrait');
		// this.state.faceCamera
		this.setState({ faceCamera: true })
		const cameraPosition = new THREE.Vector3(-200, 200, 0);
		const targetPosition = this.state.renderedAvatars[API.getCurrentUUID()] ? new THREE.Vector3(this.state.renderedAvatars[API.getCurrentUUID()].mesh.position.x, targetCameraHeight, this.state.renderedAvatars[API.getCurrentUUID()].mesh.position.z) : new THREE.Vector3(0, targetCameraHeight, 0);
		const duration = 2000;

		this._tweenCamera( cameraPosition, targetPosition, duration );
	}

	_garbageCollectTweens = (group) => {
		// console.log("garbage collecting", group.getAll().length)
		if (group) {
			group.getAll().forEach((tween) => {
				tween.stop()
				group.remove(tween)
			})
		}
	}

	goToDollhouse = (length=1) => {
		// console.log('going to dollhouse', length, this.props.people);
		this._garbageCollectTweens(this.tweenCameraGroup)

		const avatarShoulderWidth = 80;
		let radius = Math.max(160, (avatarShoulderWidth * length*3)/(2 * Math.PI));
		let height = targetCameraHeight + (length/2 * 16)

		if (this.props.people) {
			length = Object.keys(this.props.people).length;
			const theta = avatarShoulderWidth * length;
			const spiralRadius = 16 * Math.sqrt(theta);

			if (length >= 10) {
				radius = spiralRadius/2;
				height = spiralRadius/3;
			}
			if (length >= 15) {
				radius = spiralRadius/2;
				height = spiralRadius/4;
			}
		}

		const cameraOffset = this.props.autoRotate ? 0 : 80

		const cameraAvatarPosition = new THREE.Vector3( radius - cameraOffset, height + 60, radius + cameraOffset);
		const targetAvatarPosition = new THREE.Vector3( 0, height, 0 );

		const cameraPosition = new THREE.Vector3().copy(cameraAvatarPosition);
		const targetPosition = new THREE.Vector3().copy(targetAvatarPosition);
		const duration = 2000;

		this._tweenCamera( cameraPosition, targetPosition, duration );
	}

	goToSnap = () => {
		const totalExtraPeople = Object.keys(this.props.people).length-1;
		console.log("going to snap", totalExtraPeople);

		this._garbageCollectTweens(this.tweenCameraGroup)

		const pesonHeightFactor = 8;
		const pesonDistanceFactor = 20;

		let targetDistance = (totalExtraPeople * pesonDistanceFactor);
		let cameraDistance = 70;
		let headHeight = 160;
		let xOffset = 1;

		// if group override values
		if (totalExtraPeople >= 1) {
			targetDistance = (totalExtraPeople * pesonDistanceFactor);
			cameraDistance = 70 + (totalExtraPeople * pesonDistanceFactor);
			headHeight = 172 + ((totalExtraPeople+1)/2 * pesonHeightFactor);
			xOffset = -2;
		}

		if (totalExtraPeople >= 6) {
			console.log('xyz', totalExtraPeople)
			targetDistance = (totalExtraPeople * pesonDistanceFactor);
			cameraDistance = 70 + (totalExtraPeople * pesonDistanceFactor);
			headHeight = 100 + ((totalExtraPeople+1)/2 * pesonHeightFactor);
			xOffset = -1;
		}

		const cameraPosition = new THREE.Vector3(0, headHeight, cameraDistance);
		const targetPosition = new THREE.Vector3(xOffset, headHeight, targetDistance);

		this._tweenCamera(cameraPosition, targetPosition, 0)
	}

	goToAvatar = (avatarData) => {
		console.log("go to avatar")
		if (!avatarData) {return};

		const distance = 120;
		const height = 148;
		const rotation = avatarData.mesh.rotation.y;

		// todo, this doesn't really line up against people's faces
		const cameraAvatarPosition = new THREE.Vector3( avatarData.mesh.position.x + Math.sin(rotation) * distance, avatarData.mesh.position.y + height + 25, avatarData.mesh.position.z + Math.cos(rotation) * distance);
		const targetAvatarPosition = new THREE.Vector3( avatarData.mesh.position.x, avatarData.mesh.position.y + height, avatarData.mesh.position.z );

		// console.log('going', camera.position);
		const cameraPosition = new THREE.Vector3().copy( cameraAvatarPosition );
		const targetPosition = new THREE.Vector3().copy( targetAvatarPosition );
		const duration = 2000;

		this._tweenCamera( cameraPosition, targetPosition, duration );
	}

	goToSpeaker = (ID) => {
		// get index from ID
		console.log("go to speaker", ID)
		if (!ID) {
			console.log('go to dollhouse if this is null')

			setTimeout( () => {
				this.goToDollhouse()
			}, 2000);

		} else {
			let i = Object.keys(this.state.renderedAvatars).indexOf(ID);

			this.goToAvatar(this.state.renderedAvatars[ID]);
			this.setState({cameraTarget: i})
		}
	}

	goToNextSpeaker = () => {
		if ( Object.keys(this.state.renderedAvatars).length !== 0) {
			let targetSpeakerID;
			let i = this.state.cameraTarget;
			let length = Object.keys(this.state.renderedAvatars).length;

			if (this.state.cameraTarget < length-1) {
				i= i+1
			} else {
				i=0
			}

			targetSpeakerID = Object.keys(this.state.renderedAvatars)[i]
			// console.log("array", this.state.cameraTarget, i, length);

			this.goToAvatar(this.state.renderedAvatars[targetSpeakerID]);
			this.setState({cameraTarget: i})
		}
	}

	toggleBackground = (event) => {
		if (this.sceneBG) {
			this.sceneBG.updateBG()
		}
	}

	toggleOrientation = (event) => {
		if (this.controls) {
			if (!this.controls.enabledOrientation) {
				this.controls.connectTiltOrientation()
				// this.controls.connectOrientation();
			} else {
				this.controls.disconnectTiltOrientation()
				// this.controls.disconnectOrientation();
			}
			// is nice to turn bg black for nreal
			// this.scene.background = new THREE.Color( 0x000000 );
		}
	}

	_tweenCameraRotation(targetQuaternion, duration){
		// const targetOrientation = new THREE.Quaternion().set(0, 0, 0, 1).normalize();

		// gsap.to({}, {
		//     duration: 2,
		//     onUpdate: function() {
		//         camera.quaternion.slerp(targetOrientation, this.progress());
		//     }
		// });
		const cameraQuaternion = new THREE.Quaternion().copy( this.camera.quaternion );

	    new TWEEN.Tween( cameraQuaternion )
	    	.to(targetQuaternion, duration)
	        .easing( TWEEN.Easing.Quadratic.InOut )
	        .onUpdate((progress) => {
	            this.camera.quaternion.slerp(targetQuaternion, cameraQuaternion)
				// this.camera.rotation.setFromQuaternion( qm );
	    })
	    .start();
	}

	_tweenCameraLookAt = ( newTargetPosition, distance, duration ) => {
		// math to get camera position from distance
		// newTargetPosition
	}

	_tweenCamera = ( newCameraPosition, newTargetPosition, duration ) => {
		// todo: garbage collect tweens and stop tweens if someone is tryna move cam

		// this.controls.enabled = false;
		const cameraPosition = new THREE.Vector3().copy( this.camera.position );
		const targetPosition = new THREE.Vector3().copy( this.controls.target );

		// console.log([cameraPosition, newCameraPosition, targetPosition, newTargetPosition, duration])

		new TWEEN.Tween( cameraPosition, this.tweenCameraGroup )
			.to( newCameraPosition, duration )
			.easing( TWEEN.Easing.Exponential.Out )
			.onUpdate(() => {
				// console.log('tweening');
				this.camera.position.copy( cameraPosition );
			})
			.onComplete(() => {
				this.camera.position.copy( newCameraPosition );
			})
			.start();

		new TWEEN.Tween( targetPosition, this.tweenCameraGroup )
			.to( newTargetPosition, duration )
			.easing( TWEEN.Easing.Exponential.Out )
			.onUpdate(() => {
				this.camera.lookAt( targetPosition );
				this.controls.target = targetPosition;
				// if you want cool zoom mapping
				// this.controls.target.y = Utils.mapRange(this.controls.distance(), this.controls.minDistance, this.controls.maxDistance, targetCameraHeight, 0);
			} )
			.onComplete(() => {
				this.camera.lookAt( newTargetPosition );
				// if i want the cool zoom mapping
				// this.controls.target.y = Utils.mapRange(this.controls.distance(), this.controls.minDistance, this.controls.maxDistance, targetCameraHeight, 0);
				this.controls.target = newTargetPosition;
				// this.controls.enabled = true;
				this.controls.update();
			} )
			.start();
	}
}

export default Scene;
